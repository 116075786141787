import { firebaseAxios, pythonAxios } from './http';

/**
 * JOB
 */

export const getJobApi = ({ params = {}, id } = {}) =>
  pythonAxios.get(`/jobs/${id}`, {
    params,
  });

export const getJobsTitlesApi = ({ params } = {}) =>
  pythonAxios({
    method: 'GET',
    url: '/jobs/titles',
    params,
  });

export const createJobApi = ({ jobData } = {}) =>
  pythonAxios({
    method: 'POST',
    url: '/jobs',
    data: jobData,
  });

export const updateJobApi = ({ params = {}, id, jobData } = {}) =>
  pythonAxios.put(`/jobs/${id}`, jobData, { params });

export const deleteJobApi = ({ id } = {}) =>
  pythonAxios({
    method: 'delete',
    url: `/jobs/${id}`,
  });

export const updateJobStatusApi = ({ id, data } = {}) =>
  pythonAxios({
    method: 'patch',
    url: `/jobs/${id}`,
    data,
  });

/**
 * JOB USERS
 */
export const postUserToJobApi = ({ jobId, userId } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${jobId}/user/${userId}`,
  });

export const deleteUserFromJobApi = ({ jobId, userId } = {}) =>
  pythonAxios({
    method: 'delete',
    url: `/jobs/${jobId}/user/${userId}`,
  });

/**
 * JOB MAILING PERMISSION
 */
export const postJobMailingPermissionApi = ({ jobId, data } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${jobId}/mailing_permissions/add`,
    data,
  });

export const deleteJobMailingPermissionApi = ({ jobId, data } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${jobId}/mailing_permissions/remove`,
    data,
  });

/**
 * JOB ACCOUNT MANAGER
 */
export const getAccountManagersApi = ({ params = {} } = {}) =>
  pythonAxios.get('/jobs/account_managers', {
    params,
  });

export const updateJobAccountManagerApi = ({
  params = {},
  id,
  accountManager,
} = {}) =>
  pythonAxios.patch(
    `/jobs/${id}`,
    { account_manager: accountManager },
    { params },
  );

/**
 * JOB PERSONALITY CHECK
 */
export const updateJobPersonalityCheckApi = ({
  params = {},
  id,
  personalityCheck,
} = {}) =>
  pythonAxios.patch(
    `/jobs/${id}`,
    { personality_check: personalityCheck },
    { params },
  );

/**
 * JOB EXTRA INFO
 */
export const getExtraInfoApi = ({ id } = {}) =>
  pythonAxios.get(`/jobs/${id}/extra_info`);

export const updateExtraInfoApi = ({ id, data } = {}) =>
  pythonAxios.post(`/jobs/${id}/extra_info`, {
    extra_info: data,
  });

/**
 * JOB QUESTIONS
 */
export const getQuestionsApi = ({ id, questionType } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/questions/${questionType}`,
  });

export const postQuestionsApi = ({ id, questionType, data = {} } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${id}/questions/${questionType}`,
    data,
  });

/**
 * JOB FEEDBACK
 */
export const getFeedbackAnalyticsApi = ({ id } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/feedback-analytics`,
  });

/**
 * SEND JOB EMAIL
 */
export const sendNewJobEmailApi = ({ data = {} } = {}) =>
  firebaseAxios({
    method: 'post',
    url: '/newJobEmail',
    data,
  });

export const sendJobStatusChangedEmailApi = ({ data = {} } = {}) =>
  firebaseAxios({
    method: 'post',
    url: '/archivedStatusEmail',
    data,
  });

export const sendNewJobCollaboratorEmailApi = ({ data = {} } = {}) =>
  firebaseAxios({
    method: 'post',
    url: '/userAddedToJob',
    data,
  });

/**
 * JOB VERSION
 */
export const postJobVersionApi = ({ id, data = {} } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${id}/versions`,
    data,
  });

export const getCurrentJobVersionApi = ({ id } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/versions/latest`,
  });

export const putCurrentJobVersionApi = ({ id, version, description } = {}) =>
  pythonAxios({
    method: 'put',
    url: `/jobs/${id}/versions/${version}`,
    data: {
      description,
    },
  });

export const getAllJobVersionsApi = ({ id } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/versions`,
  });

/**
 * JOB CANDIDATES
 */
export const getJobCandidatesStatsApi = ({ id } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/contact-talents/status-aggregations`,
  });

export const getJobCandidatesApi = ({ id, params = null } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/contact-talents`,
    params,
  });

export const getSingleJobCandidateApi = ({ id, candidateId } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/contact-talents/${candidateId}`,
  });

export const getJobCandidatesNotInterestedStatsApi = ({ id } = {}) =>
  pythonAxios({
    method: 'get',
    url: `/jobs/${id}/contact-talents/not-interested`,
  });

export const patchJobCandidateApi = ({ id, candidateId, data } = {}) =>
  pythonAxios({
    method: 'patch',
    url: `/jobs/${id}/contactlist-direct/${candidateId}`,
    data,
  });

/**
 * DASHBOARD JOBS
 */
export const getDashboardJobsApi = ({ onSuccess, onError, params } = {}) =>
  pythonAxios({
    method: 'get',
    url: '/jobs/dashboard',
    params,
  })
    .then((response) => {
      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(response);
      }

      return response.data;
    })
    .catch((error) => {
      if (onError && typeof onError === 'function') {
        onError(error);
      }

      throw error;
    });

// filter=true means fetch validated candidates after last login
export const getNewValidatedCandidatesApi = ({
  params = { filter: false, include_closed_jobs: true },
} = {}) =>
  pythonAxios({
    method: 'get',
    url: '/jobs/dashboard/validated-candidates',
    params,
  });

// Job Owner
export const postChangeJobOwnerApi = ({ data = {} } = {}) =>
  pythonAxios({
    method: 'post',
    url: `/jobs/${data.jobId}/change-owner/${data.newOwnerId}`,
  });
