import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Icon, ButtonLink, Link, Spinner } from '@lionstep/ui';
import moment from 'moment';

// Constants
import { CANDIDATES_STATUSES } from '../../../../job.constants';
import { USER_ROLES } from '../../../../../../constants/user.const';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';
import { ReactComponent as DocumentIcon } from '../../../../../../assets/icons/Document.svg';
import CandidatePDFReportModal from '../candidatePDFReportModal/CandidatePDFReportModal';

// Config
import { TALENTPOOL_APP } from '../../../../../../config';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Selectors
import { isLoadingCandidatePDFReportSelector } from '../../candidate.selectors';

// Helpers
import { formatSummary } from './candidateBody.helpers';

// Actions
import { getCandidatePDFReport } from '../../candidate.actions';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Styles
import styles from './contentAdditionalInfo.module.scss';

const EXPAND_LIMIT = 1000;

const ContentAdditionalInfo = ({ additionalInfo, candidate, job }) => {
  const { t } = useTranslation();
  const { id: jobId } = useParams();
  const isLionstepAdmin = useIsLionstepAdmin();
  const dispatch = useDispatch();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isCandidatePDFReportModalOpen, setIsCandidatePDFReportModalOpen] =
    useState(false);

  const isLoadingCandidatePDFReport = useSelector(
    isLoadingCandidatePDFReportSelector,
  );

  const { data: user } = useUserQuery({ options: { enabled: false } });

  const enrichedAdditionalInfo = formatSummary({
    additionalInfo,
    candidate,
    job,
    t,
  });

  const handleDownloadCandidatePDFReport = async ({ hideSalary = true }) => {
    dispatch(
      getCandidatePDFReport({
        jobId: job.id,
        candidateId: candidate.id,
        data: {
          hide_salary: hideSalary,
        },
      }),
    );
  };

  const handleDownloadCandidatePDFReportCheck = () => {
    if (isLionstepAdmin) {
      setIsCandidatePDFReportModalOpen(true);
    } else if (user?.role === USER_ROLES.admin) {
      handleDownloadCandidatePDFReport({ hideSalary: false });
    } else if (user?.role === USER_ROLES.viewer) {
      handleDownloadCandidatePDFReport({ hideSalary: true });
    }
  };

  const handleExpand = () => {
    setIsExpanded((currValue) => !currValue);
  };

  const formatValidatedOnDate = (dateString) => {
    const date = moment(dateString);
    return date.format('DD.MM.YY');
  };

  return (
    <div className={styles.contentCard}>
      {isLoadingCandidatePDFReport && <Spinner position="absolute" size={50} />}
      <div className={styles.title}>
        <span className={styles.titleText}>Summary</span>
        <div className={styles.buttonContainer}>
          {isLionstepAdmin && (
            <Link
              href={`${TALENTPOOL_APP}/candidates/${candidate.id}/jobs/${jobId}`}
              target="_blank"
              className={styles.rciLink}
            >
              <InfoPlusIconWithTooltip
                name="InfoPlus"
                className={styles.infoPlusIcon}
              />
              <span className={styles.titleButtonText}>Edit via RCI</span>
            </Link>
          )}
          {candidate.status === CANDIDATES_STATUSES.interested.value &&
            enrichedAdditionalInfo && (
              <>
                <ButtonLink
                  disabled={isLoadingCandidatePDFReport}
                  onClick={handleDownloadCandidatePDFReportCheck}
                >
                  <DocumentIcon className={styles.titleButtonIcon} />
                  <span className={styles.titleButtonText}>Export to PDF</span>
                </ButtonLink>
                <CandidatePDFReportModal
                  isOpen={isCandidatePDFReportModalOpen}
                  onClose={() => setIsCandidatePDFReportModalOpen(false)}
                  onConfirm={({ hideSalary }) => {
                    setIsCandidatePDFReportModalOpen(false);
                    handleDownloadCandidatePDFReport({ hideSalary });
                  }}
                />
              </>
            )}
        </div>
      </div>
      {candidate.extra_info?.validated_at && (
        <div className={styles.validatedOn}>
          {`${t('candidate_page.body.validated_on')} ${formatValidatedOnDate(
            candidate.extra_info.validated_at,
          )}`}
        </div>
      )}
      {enrichedAdditionalInfo && candidate.in_interview_process && (
        <Alert type="warning" className={styles.alert}>
          {t('candidate_page.body.alert_in_other_interviews')}
        </Alert>
      )}
      {enrichedAdditionalInfo ? (
        <>
          <div
            className={styles.text}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                enrichedAdditionalInfo.length > EXPAND_LIMIT && !isExpanded
                  ? `${enrichedAdditionalInfo.slice(0, EXPAND_LIMIT)} ...`
                  : enrichedAdditionalInfo,
            }}
          />
          {enrichedAdditionalInfo.length > EXPAND_LIMIT && (
            <div className={styles.expander} onClick={handleExpand}>
              <Icon name={isExpanded ? 'MinusCircle' : 'PlusCircle'} />{' '}
              {isExpanded ? t('view_less') : t('view_more')}
            </div>
          )}
        </>
      ) : (
        <div className={styles.nothingToShow}>{t('no_info_to_show')}</div>
      )}
    </div>
  );
};

ContentAdditionalInfo.defaultProps = {
  additionalInfo: '',
};

ContentAdditionalInfo.propTypes = {
  additionalInfo: PropTypes.string,
  candidate: PropTypes.shape({
    status: PropTypes.string,
    id: PropTypes.string,
    in_interview_process: PropTypes.bool,
    in_interview_process_description: PropTypes.string,
    extra_info: PropTypes.shape(),
    leave_current_position_reason: PropTypes.string,
    previous_applied_to_job: PropTypes.bool,
    previous_applied_to_job_comment: PropTypes.string,
  }).isRequired,
};

export default ContentAdditionalInfo;
