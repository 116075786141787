import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames/bind';

// Components
import HeaderDropdown from './HeaderDropdown';

// Hooks
import useProfileDropdown from './useProfileDropdown';

// Styles
import styles from './headerDropdown.module.scss';

const cx = classnames.bind(styles);

const HeaderProfileDropdown = ({ user }) => {
  const { t } = useTranslation();

  const { items, handleLogout } = useProfileDropdown(user);

  const renderDropdownMenuItem = (item, onClick) => (
    <Link
      className={cx(styles.menuItem, styles.menuItemNoPadding)}
      key={item.path}
      to={item.path}
      onClick={onClick}
    >
      <span className={styles.menuItemLabel}>{item.label}</span>
    </Link>
  );

  const renderDropdownTrigger = () => (
    <span className={styles.dropdownTriggerAvatar}>
      <img
        alt={user.display_name}
        src={
          user.photoURL || 'https://0.s3.envato.com/files/35951991/80x80.JPG'
        }
      />
    </span>
  );

  const renderDropdownMenu = (onClick) => (
    <>
      {items.map((item) =>
        item.render ? renderDropdownMenuItem(item, onClick) : null,
      )}
      <div
        className={cx(styles.menuItem, styles.menuItemNoPadding)}
        onClick={() => {
          handleLogout();
          onClick();
        }}
      >
        {t('logout')}
      </div>
    </>
  );

  return (
    <HeaderDropdown
      renderDropdownTrigger={renderDropdownTrigger}
      renderDropdownMenu={renderDropdownMenu}
    />
  );
};

HeaderProfileDropdown.propTypes = {
  user: PropTypes.shape().isRequired,
};

export default HeaderProfileDropdown;
