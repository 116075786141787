import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Button, SelectV2, Label, Grid } from '@lionstep/ui';
import { capitalize } from 'lodash';
import moment from 'moment';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';

// Actions
import { refundCreditForRejected } from '../../../../../company/redux/actions';
import {
  updateCandidateFeedback,
  candidateCreditRefunded,
} from '../../candidate.actions';

// Selectors
import { isLoadingRefundCreditSelector } from '../../../../../company/redux/selectors/credits.selectors';

// Constants
import {
  CANDIDATES_STATUSES,
  CANDIDATE_DROPPED_OUT_REASONS,
  CANDIDATE_VALIDITY,
} from '../../../../job.constants';
import { CANDIDATE_FEEDBACK_OPTIONS } from '../../../../../../constants';
import { DATE_FORMAT } from '../../../../../../constants/date.const';

// Helpers
import { constantsArrayWithTranslatedLabel } from '../../../../../../support';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Config
import { TALENTPOOL_APP, ZENDESK_URL } from '../../../../../../config';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Styles
import styles from './candidateActionsAndExtraInfo.module.scss';

const CandidateActionsAndExtraInfo = ({
  job,
  candidate,
  onHireModalOpen,
  onRejectModalOpen,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: jobId } = useParams();
  const isLoadingRefund = useSelector(isLoadingRefundCreditSelector);
  const FEEDBACK_OPTIONS = constantsArrayWithTranslatedLabel(
    t,
    CANDIDATE_FEEDBACK_OPTIONS,
  ).map((option) => ({ ...option, key: option.value }));
  const isLionstepAdmin = useIsLionstepAdmin();

  const [feedback, setFeedback] = useState(
    candidate.feedback_history?.length
      ? FEEDBACK_OPTIONS.filter(
          (feedbackOption) =>
            feedbackOption.value ===
            candidate.feedback_history[candidate.feedback_history.length - 1]
              .feedback,
        )[0]
      : null,
  );
  const isSameFeedback =
    feedback?.value ===
    candidate.feedback_history?.[candidate.feedback_history.length - 1]
      ?.feedback;

  const { data: user } = useUserQuery();

  const handleOnFeedbackUpdate = async () => {
    await dispatch(
      updateCandidateFeedback({
        jobId,
        candidateId: candidate.id,
        feedback: feedback.value,
      }),
    );

    if (feedback.value === 'candidate_hired') {
      onHireModalOpen();
    }

    if (
      [
        'hired_someone_else',
        'dropped_out_after_interview',
        'refused_offer',
        'accepted_another_offer_before_interview',
        'accepted_another_offer_after_interview',
        'rejected',
      ].includes(feedback.value)
    ) {
      onRejectModalOpen();
    }
  };

  const handleOnRefundCreditClick = async () => {
    try {
      await dispatch(
        refundCreditForRejected({
          companyId: user.company_id,
          jobId,
          candidateId: candidate.id,
        }),
      );
      dispatch(candidateCreditRefunded(true));
    } catch (error) {
      dispatch(candidateCreditRefunded(false));
    }
  };

  const isRefunded = candidate.credit_refunded;
  const isEssential = job.job_product === 'essential';

  return (
    <div className={styles.candidateActionsAndExtraInfo}>
      {isLionstepAdmin && (
        <div className={styles.internalInformation}>
          <span className={styles.internalInformationItem}>
            <InfoPlusIconWithTooltip
              id="info-plus-icon-internal-info"
              className={styles.internalInformationItemInfoPlus}
            />
            <span className={styles.internalInformationItemLabel}>TP</span>
            <a
              href={`${TALENTPOOL_APP}/candidates/${candidate.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {candidate.id}
            </a>
          </span>
          <span className={styles.internalInformationItem}>
            <span className={styles.internalInformationItemLabel}>
              <Icon
                className={styles.internalInformationItemIcon}
                name="Zendesk"
              />
            </span>
            <a
              href={`${ZENDESK_URL}/agent/tickets/${candidate.ticket_id}`}
              target="_blank"
              rel="noreferrer"
            >
              {candidate.ticket_id}
            </a>
          </span>
        </div>
      )}

      {candidate.status === CANDIDATES_STATUSES.rejected.value && (
        <div>
          <div className={styles.rejectionReason}>
            {candidate.rejection_reason && (
              <div className={styles.rejectionReasonItem}>
                <p className={styles.rejectionReasonTitle}>
                  {t('candidate_page.header.rejection_reason')}
                </p>
                <p className={styles.rejectionReasonContent}>
                  {candidate.rejection_reason}
                </p>
              </div>
            )}

            {(candidate.rejected_by_email || candidate.rejected_by_name) && (
              <div className={styles.rejectionReasonItem}>
                <p className={styles.rejectionReasonTitle}>Rejected By</p>
                <p className={styles.rejectionReasonContent}>
                  {[candidate.rejected_by_name, candidate.rejected_by_email]
                    .filter((candidateInfo) => candidateInfo)
                    .join(', ')}
                </p>
              </div>
            )}
          </div>

          {isLionstepAdmin && (isRefunded || !isEssential) && (
            <>
              <hr className={styles.candidateInfoDivider} />

              <div className={styles.refundCreditSection}>
                {isRefunded && (
                  <p className={styles.creditRefundedInfo}>
                    <Icon name="Check" size={26} />{' '}
                    {t('candidate_page.header.credit_already_refunded')}
                  </p>
                )}
                {!isRefunded && !isEssential && (
                  <Button
                    type="primary"
                    ghost
                    minWidth={false}
                    disabled={isLoadingRefund}
                    onClick={handleOnRefundCreditClick}
                  >
                    {t('candidate_page.header.refund_credit_btn')}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {candidate.status === CANDIDATES_STATUSES.hired.value && (
        <div>
          <p className={styles.candidateHiredInfo}>
            <Icon name="CheckCircle" />{' '}
            {t('candidate_page.header.candidate_is_hired')}
          </p>
        </div>
      )}
      {[CANDIDATES_STATUSES.contacted.value, 'in_contact', 'hold'].includes(
        candidate.status,
      ) && (
        <div>
          <p className={styles.candidateContactedInfo}>
            {t('candidate_page.header.contacted_date')}
          </p>
          <p className={styles.candidateContactedInfoDate}>
            {candidate.extra_info?.created_at
              ? moment(candidate.extra_info.created_at).format(DATE_FORMAT)
              : 'N/A'}
          </p>
        </div>
      )}
      {candidate.status === CANDIDATES_STATUSES.not_interested.value && (
        <>
          <div>
            <p className={styles.candidateContactedInfo}>
              {t('candidate_page.header.not_interested_reason')}
            </p>
            <p className={styles.candidateContactedInfoDate}>
              {candidate.not_interested_reason_public
                ? t(
                    CANDIDATE_DROPPED_OUT_REASONS.find(
                      (reason) =>
                        reason.value === candidate.not_interested_reason_public,
                    )?.label,
                  )
                : 'N/A'}
            </p>
          </div>

          <hr className={styles.candidateInfoDivider} />

          <div>
            <p className={styles.candidateContactedInfo}>
              {t('candidate_page.header.rejection_reason')}
            </p>
            <p className={styles.candidateContactedInfoDate}>
              {candidate.rejection_reason || (
                <span className={styles.nothingToShowBase}>
                  {t('no_info_to_show')}
                </span>
              )}
            </p>
          </div>
        </>
      )}
      {candidate.status === CANDIDATES_STATUSES.interested.value && (
        <div>
          <Grid.Row gutter={[20, 20]}>
            <Grid.Col xs={24}>
              <p className={styles.candidateInterestedInfo}>
                {t('candidate_page.header.candidate_interested_title')}
              </p>
              <p className={styles.candidateInterestedInfoText}>
                {candidate.validity ===
                  CANDIDATE_VALIDITY.valid_candidate.value ||
                candidate.validity === null
                  ? t('candidate_page.header.candidate_interested_valid_desc')
                  : t(
                      'candidate_page.header.candidate_interested_acceptable_desc',
                    )}
              </p>
            </Grid.Col>
            {candidate.kombo_application && (
              <Grid.Col xs={24}>
                <p className={styles.candidateInterestedInfo}>
                  ATS Customer feedback
                </p>
                <div className={styles.candidateATS}>
                  <span className={styles.candidateATSItem}>
                    <span className={styles.candidateATSItemLabel}>
                      Synced at:
                    </span>
                    {moment(candidate.kombo_application.synced_at).format(
                      DATE_FORMAT,
                    )}
                    {',  '}
                  </span>

                  <span className={styles.candidateATSItem}>
                    <span className={styles.candidateATSItemLabel}>Stage:</span>
                    {candidate.kombo_application.current_stage?.name ||
                      'Unknown'}
                    {',  '}
                  </span>

                  <span className={styles.candidateATSItem}>
                    <span className={styles.candidateATSItemLabel}>
                      Status:
                    </span>
                    {capitalize(candidate.kombo_application.outcome) ||
                      'Unknown'}
                  </span>
                </div>
              </Grid.Col>
            )}
            <Grid.Col xs={24}>
              <Label
                bold
                htmlFor="candidateFeedback"
                className={styles.candidateFeedbackLabel}
              >
                {t('candidate_page.header.candidate_feedback')}
              </Label>
              <Grid.Row gutter={30}>
                <Grid.Col xs={14} md={16} xl={12}>
                  <SelectV2
                    name="candidateFeedback"
                    placeholder={t('candidate_page.header.candidate_feedback')}
                    options={FEEDBACK_OPTIONS}
                    value={feedback}
                    onChange={setFeedback}
                  />
                </Grid.Col>

                <Grid.Col xs={10} md={8} xl={6}>
                  <Button
                    ghost
                    block
                    disabled={isSameFeedback}
                    onClick={handleOnFeedbackUpdate}
                  >
                    {t('candidate_feedback.button')}
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid.Col>
          </Grid.Row>

          <hr className={styles.candidateInfoDivider} />

          <Grid.Row
            gutter={{ xs: [30, 30] }}
            className={styles.interestedActions}
          >
            <Grid.Col xs={24} md={12} xl={6}>
              <Button type="secondary" block onClick={onHireModalOpen}>
                {t('candidate_page.header.hire_btn')}
              </Button>
            </Grid.Col>

            <Grid.Col xs={24} md={12} xl={6}>
              <Button type="danger" ghost block onClick={onRejectModalOpen}>
                {t('candidate_page.header.reject_btn')}
              </Button>
            </Grid.Col>
          </Grid.Row>
        </div>
      )}
      {candidate.status === CANDIDATES_STATUSES.pending.value && (
        <div>
          <p className={styles.candidateCallScheduledInfo}>
            {t('candidate_page.header.candidate_scheduled_call')}
          </p>
        </div>
      )}
    </div>
  );
};

CandidateActionsAndExtraInfo.propTypes = {
  job: PropTypes.shape().isRequired,
  candidate: PropTypes.shape().isRequired,
  onHireModalOpen: PropTypes.func.isRequired,
  onRejectModalOpen: PropTypes.func.isRequired,
};

export default CandidateActionsAndExtraInfo;
