export const JOB_STATUSES_OPTIONS = [
  {
    value: null,
    label: 'No status',
  },
  {
    value: 'job_description_done',
    label: 'Job description done',
  },
  {
    value: 'search_done',
    label: 'Search done',
  },
  {
    value: 'checking_interest',
    label: 'Checking interest',
  },
  {
    value: 'additional_search_needed',
    label: 'Additional search needed',
  },
  { value: 'job_on_hold', label: 'Job on hold' },
  { value: 'job_closed', label: 'Job closed' },
];

export const JOB_STATUSES = {
  jobDescriptionDone: 'job_description_done',
  searchDone: 'search_done',
  checkingInterest: 'checking_interest',
  additionalSearchNeeded: 'additional_search_needed',
  jobOnHold: 'job_on_hold',
  jobClosed: 'job_closed',
};

export const COMPLEXITY_LEVEL_OPTIONS = [
  { value: 'level_1', label: 'Level 1 - easy' },
  { value: 'level_2', label: 'Level 2 - complex' },
  {
    value: 'level_3',
    label: 'Level 3 - very complex',
  },
];

export const RECOMMENDATION_REGION_OPTIONS = [
  { value: 'city', label: 'Job City' },
  { value: 'country', label: 'Job Country' },
  { value: 'dach', label: 'DACH' },
  { value: 'eu', label: 'EU' },
  { value: 'no_restrictions', label: 'No restrictions' },
];

export const JOB_INDUSTRIES_ACUITY_OPTIONS = [
  { label: 'Digital & Technology', value: 'Digital & Technology' },
  {
    label: 'Financial Services and Insurances',
    value: 'Financial Services and Insurances',
  },
  { label: 'Consumer & Retail / Sales', value: 'Consumer & Retail / Sales' },
  { label: 'Marketing & Media', value: 'Marketing & Media' },
  { label: 'Pharma & Life Sciences', value: 'Pharma & Life Sciences' },
  { label: 'Real Estate', value: 'Real Estate' },
  {
    label: 'Professional Services & Business Services',
    value: 'Professional Services & Business Services',
  },
  { label: 'Industrial & Automotive', value: 'Industrial & Automotive' },
  { label: 'Telecom', value: 'Telecom' },
  { label: 'Medical', value: 'Medical' },
  {
    label: 'Construction / Engineering / Architecture',
    value: 'Construction / Engineering / Architecture',
  },
];

export const PERSONALITY_TEST_TRIGGER_OPTIONS = [
  { label: 'After Call Scheduled', value: 'call_scheduled' },
  { label: 'After Validation', value: 'validated' },
];

export const JOB_EMPLOYMENT_TYPE_OPTIONS = [
  { label: 'Full time', value: 'fulltime' },
  { label: 'Part time', value: 'parttime' },
];

export const JOB_YES_NO_OPTIONS = [
  { label: 'extra_questions.yes_no_values.yes', value: 'yes' },
  { label: 'extra_questions.yes_no_values.no', value: 'no' },
];

export const LANGUAGE_LEVELS_OPTIONS = [
  {
    value: 'beginner',
    label: 'job_description_page.requirements_section.language_levels.beginner',
  },
  {
    value: 'lower_intermediate',
    label:
      'job_description_page.requirements_section.language_levels.lower_intermediate',
  },
  {
    value: 'intermediate',
    label:
      'job_description_page.requirements_section.language_levels.intermediate',
  },
  {
    value: 'upper_intermediate',
    label:
      'job_description_page.requirements_section.language_levels.upper_intermediate',
  },
  {
    value: 'advanced',
    label: 'job_description_page.requirements_section.language_levels.advanced',
  },
  {
    value: 'proficient',
    label:
      'job_description_page.requirements_section.language_levels.proficient',
  },
  {
    value: 'native',
    label: 'job_description_page.requirements_section.language_levels.native',
  },
];

export const DISPLAY_HIDE_OPTIONS = [
  {
    label: 'job_description_page.general_section.diversity_indicator.display',

    value: 'display',
  },
  {
    label: 'job_description_page.general_section.diversity_indicator.hide',

    value: 'hide',
  },
];

export const HOME_DAYS_OPTIONS = [
  {
    value: '0',
    label: 'No Home Office',
  },
  {
    value: '1',
    label:
      'job_description_page.general_section.employment_options.home_days_options.1_day',
  },
  {
    value: '2',
    label:
      'job_description_page.general_section.employment_options.home_days_options.2_days',
  },
  {
    value: '3',
    label:
      'job_description_page.general_section.employment_options.home_days_options.3_days',
  },
  {
    value: '4',
    label:
      'job_description_page.general_section.employment_options.home_days_options.4_days',
  },
  {
    value: '5',
    label:
      'job_description_page.general_section.employment_options.home_days_options.5_days',
  },
];

export const JOB_LANGUAGE_OPTIONS = [
  { value: 'english', label: 'languages_short.en' },
  { value: 'deutsch', label: 'languages_short.de' },
  { value: 'español', label: 'languages_short.es' },
  { value: 'français', label: 'languages_short.fr' },
];

export const DIRECT_REPORTS_OPTIONS = [
  { value: null, label: 'N/A' },
  { value: '1-3', label: '1-3' },
  { value: '3-10', label: '3-10' },
  { value: '10-30', label: '10-30' },
  { value: '30+', label: '30+' },
];

export const REQUIRE_DESIRED_OPTIONS = [
  {
    value: 'required',
    label: 'job_description_page.required_desired_values.required',
  },
  {
    value: 'desired',
    label: 'job_description_page.required_desired_values.desired',
  },
];

export const JOB_APPROACH_OPTIONS = [
  {
    value: 'formal',
    label: 'job_description_page.general_section.approach.options.formal',
  },
  {
    value: 'informal',
    label: 'job_description_page.general_section.approach.options.informal',
  },
];

export const YEARS_EXPERIENCE_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
];

export const CONTRACT_TYPE_OPTIONS = [
  { value: null, label: 'N/A' },
  { value: 'Limited', label: 'extra_questions.contract_types.limited' },
  {
    value: 'Unlimited',
    label: 'extra_questions.contract_types.unlimited',
  },
];

export const EMPLOYMENT_RANGE_OPTIONS = [
  { label: '10%', value: '10%' },
  { label: '20%', value: '20%' },
  { label: '30%', value: '30%' },
  { label: '40%', value: '40%' },
  { label: '50%', value: '50%' },
  { label: '60%', value: '60%' },
  { label: '70%', value: '70%' },
  { label: '80%', value: '80%' },
  { label: '90%', value: '90%' },
  { label: '100%', value: '100%' },
];

export const VERSION_SUBMIT_TYPES = {
  create: 'CREATE',
  update: 'UPDATE',
};

export const CANDIDATE_VALIDITY = {
  valid_candidate: {
    label: 'candidates_page.validity.valid_candidate',
    value: 'valid_candidate',
  },
  acceptable_candidate: {
    label: 'candidates_page.validity.acceptable_candidate',
    value: 'acceptable_candidate',
  },
  invalid_candidate: {
    label: 'candidates_page.validity.invalid_candidate',
    value: 'invalid_candidate',
  },
};

export const CANDIDATES_PER_PAGE = 12;

export const CANDIDATES_STATUSES = {
  interested: {
    label: 'candidates_page.statuses.validated',
    value: 'interested',
  },
  rejected: { label: 'candidates_page.statuses.rejected', value: 'rejected' },
  not_interested: {
    label: 'candidates_page.statuses.not_interested',
    value: 'not_interested',
  },
  pending: {
    label: 'candidates_page.statuses.call_scheduled',
    value: 'pending',
  },
  contacted: {
    label: 'candidates_page.statuses.contacted',
    value: 'contacted',
  },
  hired: { label: 'candidates_page.statuses.hired', value: 'hired' },
};

export const JOB_PRODUCT_OPTIONS_OLD = [
  {
    value: 'essential',
    label: 'Essential',
  },
  {
    value: 'professional',
    label: 'Professional',
  },
  {
    value: 'enterprise',
    label: 'Enterprise',
  },
  { value: 'talent_radar', label: 'Talent Radar' },
];

export const JOB_PRODUCT_OPTIONS = [
  {
    value: 'success_fee',
    label: 'Success Fee',
  },
  {
    value: 'success_fee_mandate',
    label: 'Success Fee Mandate',
  },
  {
    value: 'advantage',
    label: 'Advantage',
  },
  {
    value: 'on_demand',
    label: 'On demand',
  },
  {
    value: 'subscription',
    label: 'Subscription',
  },
  {
    value: 'special_case',
    label: 'Special Case',
  },
  {
    value: 'pilot',
    label: 'Pilot',
  },
];

export const CANDIDATE_DROPPED_OUT_REASONS = [
  {
    value: 'i_don_t_see_an_advance_in_my_career_1',
    label:
      'candidates_page.rejection_reason_filters.dont_see_advance_in_career',
  },
  {
    value: 'i_don_t_want_to_work_for_this_company',
    label:
      'candidates_page.rejection_reason_filters.dont_want_to_work_for_this_company',
  },
  {
    value: 'i_feel_overqualified_for_this_position',
    label:
      'candidates_page.rejection_reason_filters.i_feel_overqualified_for_this_position',
  },
  {
    value: 'i_recently_accepted_a_new_job',
    label: 'candidates_page.rejection_reason_filters.recently_accepted_new_job',
  },
  {
    value: 'i_m_currently_on_maternity_paternity_leave',
    label: 'candidates_page.rejection_reason_filters.maternity_leave',
  },
  {
    value: 'i_m_happy_with_my_current_position',
    label:
      'candidates_page.rejection_reason_filters.happy_with_current_position',
  },
  {
    value: 'i_m_looking_for_a_higher_workload',
    label:
      'candidates_page.rejection_reason_filters.looking_for_higher_workload',
  },
  {
    value: 'i_m_looking_for_a_job_in_a_different_field',
    label:
      'candidates_page.rejection_reason_filters.looking_for_job_in_different_field',
  },
  {
    value: 'i_m_looking_for_a_job_in_a_different_industry_1',
    label:
      'candidates_page.rejection_reason_filters.looking_for_job_in_different_industry',
  },
  {
    value: 'i_m_looking_for_a_lower_workload',
    label:
      'candidates_page.rejection_reason_filters.looking_for_lower_workload',
  },
  {
    value: 'i_want_a_job_with_home_office_option',
    label: 'candidates_page.rejection_reason_filters.no_homeoffice_possible',
  },
  {
    value: 'i_want_to_continue_my_work_as_a_freelancer',
    label: 'candidates_page.rejection_reason_filters.continue_as_freelancer',
  },
  {
    value: 'job_closed',
    label: 'candidates_page.rejection_reason_filters.job_closed',
  },
  {
    value: 'killer_question__additional_question_s_',
    label:
      'candidates_page.rejection_reason_filters.killer_questions_additional_questions',
  },
  {
    value: 'killer_question__language_criteria',
    label: 'candidates_page.rejection_reason_filters.killer_questions_language',
  },
  {
    value: 'killer_question__mandatory_skills_experience',
    label: 'candidates_page.rejection_reason_filters.killer_questions_skills',
  },
  {
    value: 'killer_question__min_years_of_experience',
    label:
      'candidates_page.rejection_reason_filters.killer_questions_years_of_experience',
  },
  {
    value: 'the_offered_salary_is_too_low_1',
    label: 'candidates_page.rejection_reason_filters.salary_too_low',
  },
  {
    value: 'the_work_location_does_not_fit_my_needs',
    label: 'candidates_page.rejection_reason_filters.location',
  },
  {
    value: 'did_not_answer_phone_call',
    label: 'candidates_page.rejection_reason_filters.did_not_answer_phone_call',
  },
  {
    value: 'candidate_wants_other_offers',
    label:
      'candidates_page.rejection_reason_filters.candidate_wants_other_offers',
  },
];

export const DEFAULT_SEARCHER_INFO = `Search-area:
Description of profiles e.g. hints/no-gos:
Relevant industry:
Desired companies/profiles:
Acronyms full name:
Additional Infos years of experience (Leadership/total):`;

export const NOTICE_PERIOD_OPTIONS = [
  { label: 'Undefined', value: 0, key: 0 },
  { label: '1 month', value: 1, key: 1 },
  { label: '2 months', value: 2, key: 2 },
  { label: '3 months', value: 3, key: 3 },
  { label: '4 months', value: 4, key: 4 },
  { label: '5 months', value: 5, key: 5 },
  { label: '6 months', value: 6, key: 6 },
  { label: '> 6 months', value: 7, key: 7 },
];

export const CURRENCIES_OPTIONS = [
  { value: 'EUR', label: 'EUR' },
  { value: 'CHF', label: 'CHF' },
  { value: 'USD', label: 'USD' },
  { value: 'GBP', label: 'GBP' },
];
