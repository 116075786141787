import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Icon, Tooltip } from '@lionstep/ui';
import { isEmpty } from 'lodash';

// Constants
import {
  CANDIDATES_STATUSES,
  CANDIDATE_VALIDITY,
} from '../../../../job.constants';

// Components
import NoElasticSearchBadge from '../../../../../../components/refactoring/noElasticSearchBadge';
import PreferredCompaniesTooltip from '../../../../components/PreferredCompaniesTooltip';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Helpers
import { isLowerCase } from '../../../../../../support/helpers';
import { updateCandidateInfoClasses } from './candidateHeader.helpers';

// Styles
import styles from './candidateBasicInfo.module.scss';

const cx = classnames.bind(styles);

const CandidateBasicInfo = ({ candidate }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  useLayoutEffect(() => {
    updateCandidateInfoClasses(
      styles.candidateContactInfo,
      styles.candidateContactInfoItem,
      styles.candidateContactInfoItemNoBorder,
    );

    const handleResize = () =>
      updateCandidateInfoClasses(
        styles.candidateContactInfo,
        styles.candidateContactInfoItem,
        styles.candidateContactInfoItemNoBorder,
      );

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getCandidateFullName = () => {
    if (candidate.given_name || candidate.family_name) {
      return `${candidate.given_name} ${candidate.family_name}`;
    }

    return '';
  };

  const getCandidateCurrentPosition = () => {
    if (candidate.standard_experience?.length) {
      return {
        title:
          candidate.standard_experience[0]?.title_display ||
          candidate.standard_experience[0]?.title_raw ||
          candidate.standard_experience[0]?.title_mid,
        company: candidate.standard_experience[0]?.company_name,
        industry:
          candidate.standard_experience[0]?.industry_display ??
          candidate.profile_industry?.display,
      };
    }

    return { title: 'N/A', company: '', industry: '' };
  };

  const getCandidateLocation = () => {
    if (candidate.standard_location && !isEmpty(candidate.standard_location))
      return (
        candidate.standard_location?.location_display ||
        candidate.standard_location?.location_raw
      );
    return t('candidate_page.header.no_location');
  };

  const getCandidatePhones = () => {
    if (candidate.mobile.length) {
      return {
        primary: candidate.mobile[candidate.mobile.length - 1],
        rest: candidate.mobile.slice(0, -1),
      };
    }

    return { primary: t('candidate_page.header.no_phone'), rest: [] };
  };

  const mapStatusToTranslation = () => {
    if (
      candidate.status === CANDIDATES_STATUSES.interested.value &&
      candidate.validity === CANDIDATE_VALIDITY.valid_candidate.value
    ) {
      return CANDIDATE_VALIDITY.valid_candidate.label;
    }

    if (
      candidate.status === CANDIDATES_STATUSES.interested.value &&
      candidate.validity === CANDIDATE_VALIDITY.acceptable_candidate.value
    ) {
      return CANDIDATE_VALIDITY.acceptable_candidate.label;
    }

    if (CANDIDATES_STATUSES[candidate.status]) {
      return CANDIDATES_STATUSES[candidate.status].label;
    }

    return CANDIDATES_STATUSES.contacted.label;
  };

  const fullName = getCandidateFullName();
  const position = getCandidateCurrentPosition();
  const location = getCandidateLocation();
  const phones = getCandidatePhones();

  return (
    <div className={styles.candidateInfo}>
      <div className={styles.candidateInfoFirstPart}>
        <div className={styles.candidateImgContainer}>
          <img
            src={
              candidate.image_url || `/img/dummyImages/${candidate.dummy_image}`
            }
            onError={(img) => {
              // eslint-disable-next-line no-param-reassign
              img.target.src = `/img/dummyImages/${candidate.dummy_image}`;
            }}
            alt={fullName || ''}
            className={styles.candidateImg}
          />
          {!candidate.has_es_profile && isLionstepAdmin && (
            <NoElasticSearchBadge
              className={styles.noElasticSearchIcon}
              id={candidate.id}
            />
          )}
        </div>

        <div className={styles.candidateTextInfoWrapper}>
          <div className={styles.statusWrapper}>
            <span
              className={cx(styles.candidateStatus, {
                [styles.statusHired]:
                  candidate.status === CANDIDATES_STATUSES.hired.value,
                [styles.statusInterested]:
                  candidate.status === CANDIDATES_STATUSES.interested.value,
                [styles.statusInterestedAcceptable]:
                  candidate.status === CANDIDATES_STATUSES.interested.value &&
                  candidate.validity ===
                    CANDIDATE_VALIDITY.acceptable_candidate.value,
                [styles.statusNotInterested]:
                  candidate.status === CANDIDATES_STATUSES.not_interested.value,
                [styles.statusContacted]: [
                  CANDIDATES_STATUSES.contacted.value,
                  'in_contact',
                  'hold',
                ].includes(candidate.status),
                [styles.statusCallScheduled]:
                  candidate.status === CANDIDATES_STATUSES.pending.value,
                [styles.statusRejected]:
                  candidate.status === CANDIDATES_STATUSES.rejected.value,
              })}
            >
              {candidate.status === CANDIDATES_STATUSES.hired.value && (
                <Icon name="Check" size={20} />
              )}{' '}
              {t(mapStatusToTranslation())}
            </span>
            {candidate.anonymous_flow && (
              <span className={cx(styles.candidateStatus, styles.anonymous)}>
                {t('candidate_page.header.anonymous_profile')}
              </span>
            )}
          </div>

          {fullName && (
            <span className={styles.candidateName}>
              <span>{fullName}</span>
              {candidate.preferred_company && (
                <PreferredCompaniesTooltip candidate={candidate} />
              )}
            </span>
          )}

          {![
            CANDIDATES_STATUSES.rejected.value,
            CANDIDATES_STATUSES.hired.value,
          ].includes(candidate.status) && (
            <span className={styles.candidatePosition}>
              {position.title}
              {candidate.status === CANDIDATES_STATUSES.interested.value &&
                position.company &&
                ` @ ${position.company}`}
            </span>
          )}

          {candidate.status !== CANDIDATES_STATUSES.interested.value &&
            position.industry && (
              <span className={styles.candidateIndustry}>
                {position.industry}
              </span>
            )}
        </div>
      </div>

      <div className={styles.candidateContactInfo}>
        <span className={styles.candidateContactInfoItem}>
          <Icon name="Pin" className={styles.candidateContactInfoIcon} />
          <span
            className={cx(styles.infoText, {
              [styles.locationCapitalized]:
                location && isLowerCase(location.charAt(0)),
            })}
          >
            {location}
          </span>
        </span>

        {[
          CANDIDATES_STATUSES.hired.value,
          CANDIDATES_STATUSES.interested.value,
        ].includes(candidate.status) && (
          <span className={styles.candidateContactInfoItem}>
            <Icon name="Phone" className={styles.candidateContactInfoIcon} />
            <span className={styles.infoText}>{phones.primary}</span>

            {phones.rest.length > 0 && (
              <Tooltip
                id="phones"
                place="top"
                content={phones.rest.map((phone) => (
                  <div key="phone" className={styles.morePhonesPhoneLine}>
                    {phone}
                  </div>
                ))}
              >
                <span className={styles.morePhones}>
                  {t('candidate_page.header.more_phone_numbers', {
                    number: phones.rest.length,
                  })}
                </span>
              </Tooltip>
            )}
          </span>
        )}

        {[
          CANDIDATES_STATUSES.hired.value,
          CANDIDATES_STATUSES.interested.value,
        ].includes(candidate.status) && (
          <span className={styles.candidateContactInfoItem}>
            <Icon name="Mail" className={styles.candidateContactInfoIcon} />
            {candidate.email ? (
              <a
                href={`mailto:${candidate.email}`}
                target="_blank"
                rel="noreferrer"
                className={styles.infoText}
              >
                {candidate.email}
              </a>
            ) : (
              <span className={styles.infoText}>
                {t('candidate_page.header.no_email')}
              </span>
            )}
          </span>
        )}

        {candidate.linkedin_url &&
          ([
            CANDIDATES_STATUSES.hired.value,
            CANDIDATES_STATUSES.interested.value,
            CANDIDATES_STATUSES.rejected.value,
          ].includes(candidate.status) ||
            isLionstepAdmin) && (
            <span className={styles.candidateContactInfoItem}>
              <a href={candidate.linkedin_url} target="_blank" rel="noreferrer">
                <Icon name="Linkedin" className={styles.linkedinIcon} />
              </a>
            </span>
          )}
        {/* {candidate.kombo_application && (
          <div className={styles.candidateATS}>
            <span className={styles.candidateATSItem}>
              <span className={styles.candidateATSItemLabel}>
                ATS Synced at:
              </span>
              {moment(candidate.kombo_application.synced_at).format(
                DATE_FORMAT,
              )}
              {',  '}
            </span>

            <span className={styles.candidateATSItem}>
              <span className={styles.candidateATSItemLabel}>ATS Stage:</span>
              {candidate.kombo_application.current_stage?.name || 'Unknown'}
              {',  '}
            </span>

            <span className={styles.candidateATSItem}>
              <span className={styles.candidateATSItemLabel}>ATS Status:</span>
              {capitalize(candidate.kombo_application.outcome) || 'Unknown'}
            </span>
          </div>
        )} */}
      </div>
    </div>
  );
};

CandidateBasicInfo.propTypes = {
  candidate: PropTypes.shape({
    id: PropTypes.string,
    ticket_id: PropTypes.string,
    anonymous_flow: PropTypes.bool,
    email: PropTypes.string,
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    linkedin_url: PropTypes.string,
    image_url: PropTypes.string,
    dummy_image: PropTypes.string,
    mobile: PropTypes.arrayOf(PropTypes.string),
    standard_experience: PropTypes.arrayOf(PropTypes.shape()),
    standard_location: PropTypes.shape(),
    status: PropTypes.string,
    has_es_profile: PropTypes.bool.isRequired,
    preferred_company: PropTypes.string,
  }).isRequired,
};

export default CandidateBasicInfo;
