import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Grid, Spinner, Tabs, Pagination } from '@lionstep/ui';
import classnames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// Components
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import { CandidatePlaceholder } from '../../../../components/refactoring/placeholders';
import CandidateFilter from './components/CandidateFilter';
import CandidateCard from './components/CandidateCard';
import ProfileViewersCard from './components/ProfileViewersCard';

// Hooks
import useSearchParams from '../../../../hooks/useSearchParams';

// Queries
import { useJobCompactQuery } from '../../../../queries/jobQueries';
import {
  useJobCandidatesQuery,
  useJobCandidatesMutation,
  useJobCandidatesStatusQuery,
  useJobCandidatesNotInterestedStatusQuery,
} from '../../../../queries/candidatesQueries';

// Constants
import { CANDIDATES_PER_PAGE, CANDIDATES_STATUSES } from '../../job.constants';

// Styles
import styles from './candidates.module.scss';

const cx = classnames.bind(styles);

const Candidates = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const { params, setQueryParams } = useSearchParams();
  const { page: initialPage = 1 } = params;

  const activeTabKey = params?.status || CANDIDATES_STATUSES.interested.value;
  const offset = CANDIDATES_PER_PAGE * ((params.page || initialPage) - 1);

  // Queries
  const { data: job, isFetching: isLoadingJob } = useJobCompactQuery({ id });
  const { data: { count: totalCandidates, result: candidates = [] } = {} } =
    useJobCandidatesQuery({
      jobId: id,
      status: activeTabKey,
      offset,
      filter: params.filter || null,
    });
  const { data: candidatesStatus, isFetching: isLoadingJobCandidatesStatus } =
    useJobCandidatesStatusQuery({
      jobId: id,
    });
  const {
    data: candidatesNotInterestedStats,
    isFetching: isLoadingCandidatesNotInterestedStats,
    refetch: getJobCandidatesNotInterestedStatus,
  } = useJobCandidatesNotInterestedStatusQuery({
    jobId: id,
  });

  const {
    mutate: getJobCandidatesMutation,
    isLoading: isLoadingJobCandidates,
  } = useJobCandidatesMutation({
    jobId: id,
  });

  useEffect(() => {
    getJobCandidatesMutation({
      status: activeTabKey,
      offset,
      filter: params.filter || null,
    });
  }, []);

  useEffect(() => {
    if (
      activeTabKey === CANDIDATES_STATUSES.not_interested.value &&
      !Object.keys(candidatesNotInterestedStats).length
    ) {
      getJobCandidatesNotInterestedStatus();
    }
  }, [activeTabKey]);

  const handleOnTabClick = (status) => {
    getJobCandidatesMutation(
      {
        status,
        offset: 0,
        filter: params.filter || null,
      },
      {
        onSuccess: () => {
          setQueryParams({ status, page: 1 });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
      },
    );
  };

  const handleOnPaginationChange = async (page) => {
    getJobCandidatesMutation(
      {
        status: activeTabKey,
        offset: CANDIDATES_PER_PAGE * (page - 1),
        filter: params.filter || null,
      },
      {
        onSuccess: () => {
          setQueryParams({ ...params, page });
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
      },
    );
  };

  const handleOnNotInterestedFilterChange = (filter) => {
    getJobCandidatesMutation(
      {
        status: activeTabKey,
        offset: 0,
        filter,
      },
      {
        onSuccess: () => {
          if (filter) {
            setQueryParams({ ...params, page: 1, filter });
          } else {
            const { filter: candidatesFilter, ...withoutFilter } = params;
            setQueryParams({ ...withoutFilter, page: 1 });
          }
          window.scrollTo({ top: 0, behavior: 'smooth' });
        },
      },
    );
  };

  const renderTabLabel = ({ value, label }) => (
    <span
      className={cx(styles.tabLabel, {
        [styles.tabLabelEmpty]:
          value !== activeTabKey && candidatesStatus[value] === 0,
        [styles.tabLabelActive]: value === activeTabKey,
      })}
    >
      <span className={styles.tabLabelText}>{t(label)}</span>
      <span
        className={styles.tabLabelNumber}
        data-testid={`${value}-candidates-count`}
      >
        {candidatesStatus[value]}
      </span>
    </span>
  );

  const totalCount = Object.values(candidatesStatus).reduce(
    (acc, curr) => acc + curr,
    0,
  );

  const totalPages = useMemo(() => {
    if (
      params.status === CANDIDATES_STATUSES.not_interested.value &&
      !params.filter
    ) {
      return totalCandidates + 1;
    }

    return totalCandidates;
  }, [candidates]);

  const isProfileViewersCardVisible = useMemo(() => {
    const currentPage = Number(params.page || 1);
    const maxPages = Math.ceil(totalPages / CANDIDATES_PER_PAGE);

    return (
      params.status === CANDIDATES_STATUSES.not_interested.value &&
      !params.filter &&
      candidatesStatus.not_interested - totalCandidates &&
      currentPage === maxPages
    );
  }, [
    params.page,
    candidatesStatus.not_interested,
    totalPages,
    totalCandidates,
    candidates,
  ]);

  if (isLoadingJob) return <Spinner position="fixed" size={100} />;

  if (!job) return null;

  return (
    <Grid.Container className={styles.candidates}>
      <Helmet>
        <title>{t('candidates_page.title')} | Lionstep</title>
      </Helmet>

      {/* Spinner */}
      {(isLoadingJobCandidates ||
        isLoadingJobCandidatesStatus ||
        isLoadingCandidatesNotInterestedStats) && (
        <Spinner position="fixed" size={100} />
      )}

      {/* Page Header */}
      <Grid.Row>
        <Grid.Col xs={24}>
          <PageHeading additionalInfo={job?.opening_title}>
            {t('candidates_page.title')}
            <span
              className={styles.candidatesTotalCount}
              data-testid="candidates-total-count"
            >
              {t('candidates_page.total_listed', { number: totalCount })}
            </span>
          </PageHeading>
        </Grid.Col>
      </Grid.Row>

      {/* Page Content  */}
      <Grid.Row>
        <Grid.Col xs={24}>
          {/* Tabs */}
          <Tabs
            activeKey={activeTabKey}
            onClick={handleOnTabClick}
            tabScrollerId="tabs-candidates"
          >
            {/* Tab Item */}
            {Object.values(CANDIDATES_STATUSES).map(({ value, label }) => (
              <Tabs.Item
                key={value}
                itemKey={value}
                label={renderTabLabel({ value, label })}
              >
                {/* Filters */}
                {activeTabKey === CANDIDATES_STATUSES.not_interested.value && (
                  <CandidateFilter
                    filterStats={candidatesNotInterestedStats}
                    onFilterChange={handleOnNotInterestedFilterChange}
                  />
                )}

                {/* Candidate Card */}
                <Grid.Row gutter={[30, 30]}>
                  {candidates.map((candidate) => (
                    <Grid.Col key={candidate.id} xs={24} md={12} xl={8}>
                      <CandidateCard
                        job={job}
                        candidate={candidate}
                        onClick={() =>
                          history.push(`${pathname}/${candidate.id}`)
                        }
                      />
                    </Grid.Col>
                  ))}

                  {/* Dropped Out Additional Info */}
                  {isProfileViewersCardVisible && (
                    <Grid.Col xs={24} md={12} xl={8}>
                      <ProfileViewersCard
                        hasNotInterestedCandidates={!!totalCandidates}
                        profileViewersNumber={
                          candidatesStatus.not_interested - totalCandidates
                        }
                      />
                    </Grid.Col>
                  )}

                  {/* NO Candidates Placeholder */}
                  {!candidates.length &&
                    !isLoadingJobCandidates &&
                    !isProfileViewersCardVisible && (
                      <Grid.Col xs={24} className={styles.placeholder}>
                        <CandidatePlaceholder>
                          {t('candidates_page.no_results_to_show')}
                        </CandidatePlaceholder>
                      </Grid.Col>
                    )}
                </Grid.Row>
              </Tabs.Item>
            ))}
          </Tabs>
        </Grid.Col>

        {/* Pagination */}
        {(!!candidates.length || isProfileViewersCardVisible) &&
          !!totalPages &&
          totalPages / CANDIDATES_PER_PAGE > 1 && (
            <Grid.Col xs={24}>
              <Pagination
                className={styles.pagination}
                currentPage={Number(initialPage)}
                totalPages={totalPages}
                pageSize={CANDIDATES_PER_PAGE}
                onChange={handleOnPaginationChange}
              />
            </Grid.Col>
          )}
      </Grid.Row>
    </Grid.Container>
  );
};

export default Candidates;
