import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Link as LinkUi, Tooltip } from '@lionstep/ui';
import { IconNotes } from '@tabler/icons-react';

// Components
import JobDropdown from '../JobDropdown';

// Constants
import { JOBS_PORTAL_APP } from '../../../../../../config';
import { JOB_STATUSES_KEY_VALUE } from '../../../../../../constants';
import { DATE_FORMAT } from '../../../../../../constants/date.const';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Styles
import styles from './jobCard.module.scss';

const JobCard = ({ job, archived, onActionModalOpen }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  return (
    <div className={styles.jobCard}>
      {/* Header */}
      <div className={styles.header}>
        {/* Actions */}
        <div className={styles.actions}>
          <div className={styles.actionGroup}>
            <div className={styles.actionItem}>
              <Link
                to={`/edit-job/${job.id}`}
                data-testid={`edit-job-${job.id}`}
              >
                <Icon className={styles.actionItemIcon} name="Edit" />
                {t('dashboard_page.job_card.actions.edit')}
              </Link>
            </div>
            {isLionstepAdmin && (
              <div className={styles.actionItem}>
                <Link
                  to={`/edit-job/${job.id}/admin`}
                  data-testid={`admin-job-${job.id}`}
                >
                  <Icon className={styles.actionItemIcon} name="Shield" />
                  {t('admin_actions_page.title')}
                </Link>
              </div>
            )}
            <div className={styles.actionItem}>
              <Link
                to={`/edit-job/${job.id}/insights`}
                data-testid={`insights-job-${job.id}`}
              >
                <Icon className={styles.actionItemIcon} name="Insights" />
                {t('insights_page.title')}
              </Link>
            </div>
          </div>
          <div className={styles.actionGroup}>
            <div className={styles.actionItem}>
              <LinkUi
                href={`${JOBS_PORTAL_APP}/job/${job.slug}`}
                data-testid={`preview-job-${job.id}`}
                target="_blank"
              >
                {t('dashboard_page.job_card.actions.preview')}
                <Icon className={styles.actionItemIcon} name="ArrowRight" />
              </LinkUi>
            </div>
            <div className={styles.actionItem}>
              <JobDropdown
                job={job}
                archived={archived}
                onActionModalOpen={onActionModalOpen}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Body */}
      <div className={styles.body}>
        <div className={styles.info}>
          <div className={styles.dateAndStatus}>
            {/* Status */}
            <div className={styles.status}>
              <span className={styles.statusLabel}>Status: </span>
              {job.status
                ? `${JOB_STATUSES_KEY_VALUE[job.status]}${
                    job.last_status_changed_date
                      ? ` (${moment(job.last_status_changed_date).format(
                          DATE_FORMAT,
                        )})`
                      : ''
                  }`
                : JOB_STATUSES_KEY_VALUE.no_status}
            </div>
            {job.kombo_job && (
              <div className={styles.atsStatus}>
                <span className={styles.atsLabel}>ATS status: </span>
                <span className={styles.ats}>{job.kombo_job.status}</span>
              </div>
            )}

            {job.is_one_landing_page && (
              <Tooltip
                id="dashboard-one-landing-page"
                content={<span>One Landing Page</span>}
              >
                <IconNotes className={styles.oneLandingPageIcon} />
              </Tooltip>
            )}
          </div>
          {/* Dates */}
          <div className={styles.date}>
            <span className={styles.dateLabel}>
              {t('dashboard_page.job_card.start_search_date')}{' '}
            </span>
            {job.first_status_changed_date
              ? moment(job.first_status_changed_date).format(DATE_FORMAT)
              : '-'}
          </div>
        </div>

        {/* Job Title */}
        <div className={styles.title}>
          <Link
            className={styles.titleLink}
            data-testid={`job-candidates-${job.id}`}
            to={`/edit-job/${job.id}/candidates`}
          >
            {job.opening_title}
          </Link>
        </div>
        {/* Replacement name */}
        <div className={styles.replacementName}>
          {job.replacement_name && (
            <>
              <span className={styles.replacementNameLabel}>
                Replacement company:
              </span>{' '}
              {job.replacement_name}
            </>
          )}
        </div>
        {/* Job Locations */}
        <div className={styles.location}>
          <Icon name="Pin" />
          {job.job_location_name || 'Unknown'}
        </div>
      </div>
      {/* Footer */}
      <div className={styles.footer}>
        {/* Candidates */}
        <div className={styles.candidates}>
          <span className={styles.candidatesLabel}>Candidates</span>
          <span className={styles.candidatesCount}>
            {/* Total Candidates */}
            <span className={styles.totalCount}>
              {t('dashboard_page.job_card.candidates_total', {
                number: Object.values(job.contacts).reduce(
                  (acc, curr) => acc + curr,
                  0,
                ),
              })}
            </span>
            {/* In Contact Candidates */}
            <span className={styles.candidatesInContact}>
              {t('dashboard_page.job_card.candidates_in_contact', {
                number:
                  job.contacts.contacted +
                  job.contacts.in_contact +
                  job.contacts.hold,
              })}
            </span>
          </span>
        </div>
        {/* Action Buttons */}
        <div className={styles.actionButtons}>
          <span className={styles.buttonItem}>
            <Link
              to={`/edit-job/${job.id}/candidates?status=interested`}
              data-testid={`validated-candidates-${job.id}`}
            >
              <Button block>
                {t('candidates_page.statuses.validated')} (
                {job.contacts.interested})
              </Button>
            </Link>
          </span>
          <span className={styles.buttonItem}>
            <Link
              to={`/edit-job/${job.id}/candidates?status=hired`}
              data-testid={`hired-candidates-${job.id}`}
            >
              <Button block>
                {t('candidates_page.statuses.hired')} ({job.contacts.hired})
              </Button>
            </Link>
          </span>
          <span className={styles.buttonItem}>
            <Link
              to={`/edit-job/${job.id}/candidates?status=pending`}
              data-testid={`call-scheduled-candidates-${job.id}`}
            >
              <Button block>
                {t('candidates_page.statuses.call_scheduled')} (
                {job.contacts.pending})
              </Button>
            </Link>
          </span>
          <span className={styles.buttonItem}>
            <Link
              to={`/edit-job/${job.id}/candidates?status=rejected`}
              data-testid={`rejected-candidates-${job.id}`}
            >
              <Button block>
                {t('candidates_page.statuses.rejected')} (
                {job.contacts.rejected})
              </Button>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

JobCard.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    status: PropTypes.string,
    first_status_changed_date: PropTypes.string,
    last_status_changed_date: PropTypes.string,
    opening_title: PropTypes.string,
    job_location_name: PropTypes.string,
    replacement_name: PropTypes.string,
    contacts: PropTypes.shape({
      bad_match: PropTypes.number,
      call_done: PropTypes.number,
      contacted: PropTypes.number,
      hired: PropTypes.number,
      hold: PropTypes.number,
      in_contact: PropTypes.number,
      interested: PropTypes.number,
      not_interested: PropTypes.number,
      pending: PropTypes.number,
      rejected: PropTypes.number,
    }),
  }).isRequired,
  archived: PropTypes.bool.isRequired,
};
export default JobCard;
