import axios from 'axios';
import { toast } from '@lionstep/ui';
import { QueryClient } from '@tanstack/react-query';

// Lib
import { getRecaptcha } from '../support';

// Store
import store from '../redux/store';

import {
  PYTHON_API,
  RAILS_API,
  LAYER1_API,
  FIREBASE_FUNCTIONS_URL,
} from '../config';

// Constants
import { PUBLIC_ROUTES } from '../constants/user.const';

// REQUEST INTERCEPTOR
const createRequestInterceptorResolve = (config) => {
  const sessionId = localStorage.getItem('sessionId');
  const commonHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (sessionId && sessionId !== 'null') {
    commonHeaders.Authorization = `Basic ${sessionId}`;
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      ...commonHeaders,
    },
  };
};

const createRequestInterceptorReject = (error) => Promise.reject(error);

// RESPONSE INTERCEPTOR
const createResponseInterceptorResolve = (response) => response;
const createResponseInterceptorReject = async (error) => {
  const refreshToken = localStorage.getItem('refreshToken');
  const originalConfig = error.config;
  const queryClient = new QueryClient({});

  if (
    refreshToken &&
    refreshToken !== 'null' &&
    (error?.response?.status === 401 || error?.response?.status === 403) &&
    !originalConfig.retry
  ) {
    originalConfig.retry = true;

    try {
      const isLayer1 = originalConfig.baseURL === `${LAYER1_API}/v1`;
      const recaptchaToken = await getRecaptcha();

      const { data } = await axios({
        method: 'POST',
        url: `${PYTHON_API}/api/v1/user/refresh`,
        data: {
          refresh_token: refreshToken,
          recaptcha_token: recaptchaToken,
        },
      });

      localStorage.setItem('sessionId', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('logged', true);

      return isLayer1
        ? // eslint-disable-next-line no-use-before-define
          layer1Axios(originalConfig)
        : // eslint-disable-next-line no-use-before-define
          pythonAxios(originalConfig);
    } catch (retryError) {
      toast.dismiss();

      localStorage.setItem('sessionId', null);
      localStorage.setItem('refreshToken', null);
      localStorage.setItem('logged', false);

      store.dispatch({ type: 'LOGOUT_USER' });

      await queryClient.removeQueries();

      window.location.replace('/login');
    }
  } else if (
    error?.response?.status === 401 ||
    error?.response?.status === 403
  ) {
    toast.dismiss();

    localStorage.setItem('sessionId', null);
    localStorage.setItem('refreshToken', null);
    localStorage.setItem('logged', false);

    store.dispatch({ type: 'LOGOUT_USER' });

    await queryClient.removeQueries();

    // To prevent infinite loop when trying to fetch user profile
    // And prevent redirecting from public routes like register when accessing them directly
    if (!PUBLIC_ROUTES.includes(window.location.pathname)) {
      window.location.replace('/login');
    }
  }

  return Promise.reject(error);
};

// Instances
export const pythonAxios = axios.create({
  baseURL: `${PYTHON_API}/api/v1`,
});
pythonAxios.interceptors.request.use(
  createRequestInterceptorResolve,
  createRequestInterceptorReject,
);
pythonAxios.interceptors.response.use(
  createResponseInterceptorResolve,
  createResponseInterceptorReject,
);

export const layer1Axios = axios.create({
  baseURL: `${LAYER1_API}`,
});
layer1Axios.interceptors.request.use(
  createRequestInterceptorResolve,
  createRequestInterceptorReject,
);
layer1Axios.interceptors.response.use(
  createResponseInterceptorResolve,
  createResponseInterceptorReject,
);

export const rubyAxios = axios.create({
  baseURL: `${RAILS_API}/v1`,
});
rubyAxios.interceptors.request.use(
  createRequestInterceptorResolve,
  createRequestInterceptorReject,
);
rubyAxios.interceptors.response.use(
  createResponseInterceptorResolve,
  createResponseInterceptorReject,
);

export const firebaseAxios = axios.create({
  baseURL: `${FIREBASE_FUNCTIONS_URL}`,
});
firebaseAxios.interceptors.request.use(
  createRequestInterceptorResolve,
  createRequestInterceptorReject,
);
firebaseAxios.interceptors.response.use(
  createResponseInterceptorResolve,
  createResponseInterceptorReject,
);
