import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Heading, Spinner, toast } from '@lionstep/ui';

// Components
import ContentCard from '../../../../../../components/refactoring/contentCard';
import CollaboratorsTableRow from './CollaboratorsTableRow';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Queries
import {
  useUserCollaboratorRoleMutation,
  useDeleteUserCollaboratorMutation,
} from '../../../../../../queries/userQueries';

// Styles
import styles from './collaboratorsTable.module.scss';

const CollaboratorsTable = ({ isError, collaborators, userData }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  // Change collaborator role
  const {
    mutate: updateUserCollaboratorRole,
    isLoading: isLoadingUpdateUserCollaboratorRole,
  } = useUserCollaboratorRoleMutation({
    onError: (error) => {
      toast.error('There was an error updating collaborator.', {
        id: 'UPDATE_COLLABORATOR_ROLE',
        statusCode: error?.response?.status,
      });
    },
    onSuccess: () => {
      toast.success('Collaborator role successfully updated.', {
        id: 'UPDATE_COLLABORATOR_ROLE',
      });
    },
  });

  const handleUpdateUserCollaboratorRole = (collaborator, role) => {
    updateUserCollaboratorRole({
      data: { role_name: role, user_id: collaborator.local_id },
    });
  };

  // Delete Collaborator
  const {
    mutate: deleteUserCollaborator,
    isLoading: isLoadingDeleteCollaborator,
  } = useDeleteUserCollaboratorMutation({
    onError: (error) => {
      toast.error('There was an error while deleting collaborator', {
        id: 'DELETING_COLLABORATOR',
        statusCode: error?.response?.status,
      });
    },
    onSuccess: () => {
      toast.success('Collaborator successfully deleted.', {
        id: 'DELETE_COLLABORATOR',
      });
    },
  });

  const handleDeleteUserCollaborator = (id) => {
    deleteUserCollaborator({ id });
  };

  return (
    <ContentCard>
      <Heading level={3} className={styles.title}>
        {t('collaborator_page.collaborators.title')}
      </Heading>
      {(isLoadingDeleteCollaborator || isLoadingUpdateUserCollaboratorRole) && (
        <Spinner position="fixed" size={100} />
      )}
      <Table>
        <Table.Header>
          <Table.HeaderRow>
            <Table.HeaderCell style={{ width: '20%' }}>
              {t('collaborator_page.collaborators.table_name')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '35%' }}>
              {t('collaborator_page.collaborators.table_email')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>
              {t('collaborator_page.collaborators.table_role')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }}>
              {t('collaborator_page.collaborators.table_comment')}
            </Table.HeaderCell>
            <Table.HeaderCell style={{ width: '15%' }} textAlign="center">
              {t('collaborator_page.collaborators.table_actions')}
            </Table.HeaderCell>
          </Table.HeaderRow>
        </Table.Header>
        <Table.Body>
          {!isError ? (
            collaborators
              .filter(
                (collaborator) =>
                  isLionstepAdmin ||
                  collaborator.email.split('@')[1] !== 'lionstep.com',
              )
              .map((collaborator) => (
                <CollaboratorsTableRow
                  key={collaborator.id}
                  collaborator={collaborator}
                  userData={userData}
                  onUpdateUserCollaboratorRole={
                    handleUpdateUserCollaboratorRole
                  }
                  onDeleteUserCollaborator={handleDeleteUserCollaborator}
                />
              ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={5} textAlign="center">
                NO DATA
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </ContentCard>
  );
};

export default CollaboratorsTable;
