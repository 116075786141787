import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid, SelectV2 } from '@lionstep/ui';

// Selectors
import { appLanguageSelector } from '../../../../../app/redux/selectors/language.selectors';

// Helpers
import { collaboratorsOptionMapper } from '../../../../dashboard.helper';

// Queries
import { useUserCollaborators } from '../../../../../../queries/userQueries';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';

// Hooks
import useSearchParams from '../../../../../../hooks/useSearchParams';

// Styles
import styles from './filters.module.scss';

const Filters = () => {
  // Hooks
  const { t } = useTranslation();
  const appLanguage = useSelector(appLanguageSelector);

  // Consts
  const DEFAULT_COLLABORATOR_OPTION = {
    label: t('all_collaborators'),
    value: undefined,
  };

  const [selectedCollaborator, setSelectedCollaborator] = useState(
    DEFAULT_COLLABORATOR_OPTION,
  );

  const { data: collaborators } = useUserCollaborators({
    options: { enabled: false },
  });
  const { setQueryParams, params } = useSearchParams();

  useEffect(() => {
    if (selectedCollaborator.value === undefined) {
      setSelectedCollaborator(DEFAULT_COLLABORATOR_OPTION);
    }
  }, [appLanguage]);

  useEffect(() => {
    if (params.collaborator && collaborators.length) {
      const collaborator = collaborators.find(
        (colab) => colab.local_id === params.collaborator,
      );

      if (collaborator) {
        setSelectedCollaborator({
          label: collaborator.display_name,
          value: collaborator.local_id,
        });
      }
    }
  }, [collaborators]);

  // Handlers
  const handleOnCollaboratorsSelect = (collaborator) => {
    setSelectedCollaborator(collaborator);
    setQueryParams({
      page: 1,
      ...(collaborator.value && { collaborator: collaborator.value }),
    });
  };

  return (
    <Grid.Row gutter={30} className={styles.filter}>
      <Grid.Col xs={24} className={styles.filterLabel}>
        {t('filters')} <InfoPlusIconWithTooltip />
      </Grid.Col>
      <Grid.Col xs={24} md={12} lg={8} xl={6}>
        <SelectV2
          value={selectedCollaborator}
          onChange={handleOnCollaboratorsSelect}
          options={[
            DEFAULT_COLLABORATOR_OPTION,
            ...collaboratorsOptionMapper(collaborators),
          ]}
        />
      </Grid.Col>
    </Grid.Row>
  );
};

export default Filters;
