import React from 'react';
import { Accordion } from '@lionstep/ui';

// React Query
import useValidatedCandidatesQuery from './queries';

// Components
import ValidatedCandidatesHeader from './ValidatedCandidatesHeader';
import ValidatedCandidatesPlaceholder from './ValidatedCandidatesPlaceholder';
import ValidatedCandidatesJobsList from './ValidatedCandidatesJobsList';

// Styles
import styles from './validatedCandidates.module.scss';

const ValidatedCandidates = ({ isLoadingDashboardJobs }) => {
  const {
    data: newValidatedCandidates,
    isFetching,
    isPlaceholderData,
  } = useValidatedCandidatesQuery();

  const totalCandidates = newValidatedCandidates.reduce(
    (acc, curr) => acc + curr.validated_candidates.length,
    0,
  );

  return (
    <>
      <Accordion withArrows className={styles.validatedCandidates}>
        <Accordion.Item itemKey="validated-candidates">
          <Accordion.ItemHeader className={styles.accordionHeader}>
            <ValidatedCandidatesHeader totalCandidates={totalCandidates} />
          </Accordion.ItemHeader>

          <Accordion.ItemBody className={styles.accordionBody}>
            {newValidatedCandidates.length > 0 ? (
              <ValidatedCandidatesJobsList
                newValidatedCandidates={newValidatedCandidates}
                totalCandidates={totalCandidates}
              />
            ) : (
              <ValidatedCandidatesPlaceholder
                isFetching={
                  !isLoadingDashboardJobs && isFetching && isPlaceholderData
                }
              />
            )}
          </Accordion.ItemBody>
        </Accordion.Item>
      </Accordion>
      <div className={styles.divider} />
    </>
  );
};

export default ValidatedCandidates;
