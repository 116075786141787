import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, ButtonLink } from '@lionstep/ui';
import moment from 'moment';
import DayPickerSingleDateController from 'react-dates/lib/components/DayPickerSingleDateController';

// Actions
import { updatePurchaseExpirationDate } from '../../../../redux/actions';

// Selectors
import { companySelector } from '../../../../redux/selectors/company.selectors';

// Constants
import { DATE_FORMAT } from '../../../../../../constants/date.const';

// Styles
import styles from './editExpirationDateModal.module.scss';

const EditExpirationDateModal = ({ selectedPurchase, onClose }) => {
  const dispatch = useDispatch();
  const company = useSelector(companySelector);
  const [updatedExpireData, setUpdatedExpireDate] = useState(null);

  useEffect(() => {
    if (!selectedPurchase && updatedExpireData) {
      setUpdatedExpireDate(null);
    }
  }, [selectedPurchase]);

  const handleOnDateUpdated = () => {
    dispatch(
      updatePurchaseExpirationDate({
        companyId: company.company_id,
        supplyId: selectedPurchase.id,
        data: { availability: moment(updatedExpireData).format('YYYY-MM-DD') },
        onSuccess: onClose,
      }),
    );
  };

  return (
    <Modal show={Boolean(selectedPurchase)} onClose={onClose}>
      <Modal.Header>Edit Availability</Modal.Header>
      <Modal.Body>
        <div className={styles.calendarWrapper}>
          <DayPickerSingleDateController
            date={moment(updatedExpireData || selectedPurchase?.availability)}
            monthFormat={DATE_FORMAT}
            onDateChange={setUpdatedExpireDate}
            numberOfMonths={1}
            focused
            noBorder
          />

          {updatedExpireData && (
            <div className={styles.warningMessage}>
              {`Are you sure you want to set the expire date to: ${moment(
                updatedExpireData,
              ).format(DATE_FORMAT)}?`}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer onClose={onClose}>
        <div className={styles.modalFooterWrapper}>
          <Button
            block
            disabled={!updatedExpireData}
            className={styles.confirmButton}
            onClick={handleOnDateUpdated}
          >
            Yes
          </Button>
          <ButtonLink ghost onClick={onClose}>
            No
          </ButtonLink>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

EditExpirationDateModal.defaultProps = {
  selectedPurchase: null,
};

EditExpirationDateModal.propTypes = {
  selectedPurchase: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};

export default EditExpirationDateModal;
