import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@lionstep/ui';

// Api
import { layer1Axios } from '../api/http';
import {
  postUserCollaboratorApi,
  deleteCollaboratorApi,
  updateCollaboratorRoleApi,
} from '../api/user.api';

// Constants
import { USER_ROLES } from '../constants/user.const';

const USER_QUERY_KEYS = {
  user: 'user',
  userLogin: 'userLogin',
  userLogout: 'userLogout',
  userRegister: 'userRegister',
  userCollaborators: 'userCollaborators',
  userCreateCollaborator: 'userCreateCollaborator',
  usersSignupCollaborator: 'usersSignupCollaborator',
};

// LOGIN
const postUserLogin = ({ data: loginData }) =>
  layer1Axios({
    method: 'POST',
    url: '/v1/users/login',
    data: loginData,
  }).then(({ data }) => data);

export const useUserLoginMutation = ({ options = {} } = {}) =>
  useMutation([USER_QUERY_KEYS.userLogin], postUserLogin, {
    ...options,
    onSuccess: (data) => {
      // TEMPORARILY
      const loginTime = Math.floor(new Date().getTime() / 1000);

      localStorage.setItem('start', loginTime);
      localStorage.setItem('sessionId', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('logged', true);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });

export const useUserLogoutQuery = ({ history, clearReduxStore } = {}) => {
  const queryClient = useQueryClient();

  return useQuery(
    [USER_QUERY_KEYS.userLogout],
    async () => {
      history.push('/');

      localStorage.setItem('sessionId', null);
      localStorage.setItem('refreshToken', null);
      localStorage.setItem('logged', false);

      clearReduxStore(); // TODO: remove this when redux is removed

      await queryClient.removeQueries();

      return null;
    },
    {
      enabled: false,
    },
  );
};
// REGISTER
const postUserRegister = ({ data: loginData }) =>
  layer1Axios({
    method: 'POST',
    url: '/v1/users/register',
    data: loginData,
  }).then(({ data }) => data);

export const useUserRegisterMutation = ({ options = {} } = {}) =>
  useMutation([USER_QUERY_KEYS.userRegister], postUserRegister, {
    ...options,
    onSuccess: (data) => {
      // TEMPORARILY
      const loginTime = Math.floor(new Date().getTime() / 1000);

      localStorage.setItem('start', loginTime);
      localStorage.setItem('sessionId', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);
      localStorage.setItem('logged', true);

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
  });

// PROFILE
const getUser = () =>
  layer1Axios({
    method: 'GET',
    url: '/v1/users/profile',
  }).then(({ data }) => data);

export const useUserQuery = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  return useQuery([USER_QUERY_KEYS.user], getUser, {
    ...options,
    staleTime: !queryClient.getQueryData([USER_QUERY_KEYS.user]) ? 0 : Infinity,
  });
};

const putUser = ({ data: userData }) =>
  layer1Axios({
    method: 'PUT',
    url: '/v1/users',
    data: userData,
  }).then(({ data }) => data);

export const useUserMutation = ({ options = {} } = {}) => {
  const queryClient = useQueryClient();

  return useMutation([USER_QUERY_KEYS.user], putUser, {
    ...options,
    onSuccess: ({ user }) => {
      queryClient.setQueryData([USER_QUERY_KEYS.user], user);

      toast.success('Profile information  saved!', {
        id: 'UPDATE_USER_SUCCESS',
      });

      if (options.onSuccess) {
        options.onSuccess();
      }
    },
    onError: (error) => {
      toast.error('Profile information not saved!', {
        id: 'UPDATE_USER_FAIL',
        statusCode: error?.response?.status,
      });

      if (options.onError) {
        options.onError();
      }
    },
  });
};

// Collaborators
const getUserCollaborators = () =>
  layer1Axios({
    method: 'get',
    url: '/v1/users/collaborators',
  }).then(({ data }) => data);

export const useUserCollaborators = ({ options = {} } = {}) =>
  useQuery([USER_QUERY_KEYS.userCollaborators], getUserCollaborators, {
    placeholderData: [],
    staleTime: 300000, // 5 minutes
    ...options,
  });

export const useCreateUserCollaboratorMutation = ({
  onError,
  onSuccess,
  options = {},
} = {}) =>
  useMutation(
    [USER_QUERY_KEYS.userCreateCollaborator],
    postUserCollaboratorApi,
    {
      onSuccess: (data, variables) => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(data, variables);
        }
      },
      onError: (error) => {
        if (onError && typeof onError === 'function') {
          onError(error);
        }
      },
      ...options,
    },
  );
// Signup Collaborator
const postSignupCollaborator = ({ data: collaboratorData }) =>
  layer1Axios({
    method: 'post',
    url: '/v1/users/signup-collaborator',
    data: collaboratorData,
  }).then(({ data }) => data);

export const useSignupCollaboratorMutation = ({ options = {} } = {}) =>
  useMutation(
    [USER_QUERY_KEYS.usersSignupCollaborator],
    postSignupCollaborator,
    {
      ...options,
      onSuccess: () => {
        toast.success('Collaborator signin success', {
          id: 'SIGNUP_COLLABORATOR_SUCCESS',
        });

        if (options.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error) => {
        toast.error('Collaborator signin failed', {
          id: 'SIGNUP_COLLABORATOR_FAIL',
          statusCode: error?.response?.status,
        });

        if (options.onError) {
          options.onError();
        }
      },
    },
  );

// COLLABORATORS
export const useUserCollaboratorRoleMutation = ({ onError, onSuccess }) => {
  const queryClient = useQueryClient();

  return useMutation(updateCollaboratorRoleApi, {
    onSuccess: (data, variables) => {
      const { user_id, role_name } = variables.data;

      queryClient.setQueryData(['userCollaborators'], (currentData) =>
        currentData.map((collaborator) =>
          collaborator.local_id === user_id
            ? {
                ...collaborator,
                role:
                  role_name === USER_ROLES.viewer
                    ? USER_ROLES.viewer
                    : USER_ROLES.admin,
              }
            : collaborator,
        ),
      );

      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(data, variables);
      }
    },
    onError: (error) => {
      if (onError && typeof onError === 'function') {
        onError(error);
      }
    },
  });
};

export const useDeleteUserCollaboratorMutation = ({ onError, onSuccess }) => {
  const queryClient = useQueryClient();

  return useMutation(deleteCollaboratorApi, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData(['userCollaborators'], (currentData) =>
        currentData.filter(
          (collaborator) => collaborator.local_id !== variables.id,
        ),
      );

      if (onSuccess && typeof onSuccess === 'function') {
        onSuccess(data, variables);
      }
    },
    onError: (error) => {
      if (onError && typeof onError === 'function') {
        onError(error);
      }
    },
  });
};
