import { useQuery } from '@tanstack/react-query';
import { toast } from '@lionstep/ui';
import { getNewValidatedCandidatesApi } from '../../../../../../api/job.api';

const queryKey = 'validatedCandidates';

const useValidatedCandidatesQuery = () =>
  useQuery([queryKey], getNewValidatedCandidatesApi, {
    select: (responseData) => responseData.data.jobs,
    placeholderData: { data: { jobs: [] } },
    staleTime: 300000, // 5 minutes
    onError: (error) => {
      toast.error('Error fetching new validated candidates', {
        id: queryKey,
        statusCode: error?.response?.status,
      });
    },
  });

export default useValidatedCandidatesQuery;
