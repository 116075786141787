import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Styles
import styles from './infoPlusIconWithTooltip.module.scss';

const cx = classnames.bind(styles);

const InfoPlusIconWithTooltip = ({
  id,
  className,
  wrapperClassName,
  ...restProps
}) => (
  <Tooltip
    id={id}
    place="top"
    content={<span>Visible to Info+ account only</span>}
  >
    <span className={cx(styles.iconWrapper, wrapperClassName)}>
      <Icon
        {...restProps}
        className={cx(styles.icon, className)}
        name="InfoPlus"
      />
    </span>
  </Tooltip>
);

InfoPlusIconWithTooltip.defaultProps = {
  id: 'info-plus-icon',
  className: '',
  wrapperClassName: '',
};

InfoPlusIconWithTooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default InfoPlusIconWithTooltip;
