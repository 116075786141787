import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@lionstep/ui';

// Selectors
import { candidateSelector } from '../../candidate.selectors';

// Queries
import { useJobCompactQuery } from '../../../../../../queries/jobQueries';

// Constants
import { CANDIDATES_STATUSES } from '../../../../job.constants';

// Helpers
import {
  workingExperienceFormatter,
  educationFormatter,
  capabilitiesSkillsFormatter,
  capabilitiesLanguagesFormatter,
} from './candidateBody.helpers';

// Components
import ContentAdditionalInfo from './ContentAdditionalInfo';
import ContentList from './ContentList';
import ContentDocuments from './ContentDocuments';
import ContentKeyInfo from './ContentKeyInfo';

const CandidateBody = () => {
  const { id: jobId } = useParams();
  const candidate = useSelector(candidateSelector);
  const { t } = useTranslation();
  // Queries
  const { data: job } = useJobCompactQuery({
    id: jobId,
    options: { enabled: false },
  });

  // Career Path
  const workingExperienceItems = workingExperienceFormatter({
    workingExperience: candidate.standard_experience,
    t,
    hideActivePositionCompany: ![
      CANDIDATES_STATUSES.interested.value,
      CANDIDATES_STATUSES.hired.value,
      CANDIDATES_STATUSES.rejected.value,
    ].includes(candidate.status),
  });
  const educationItems = educationFormatter(candidate.education);

  // Capabilities
  const skillsSectionItems = capabilitiesSkillsFormatter(
    candidate.standard_skills,
  );
  const languagesSectionItems = capabilitiesLanguagesFormatter(
    candidate.standard_languages,
  );

  return (
    <Tabs tabScrollerId="candidateInfo">
      {/* Job Matching */}
      <Tabs.Item
        label={t('job_matching')}
        itemKey="jobMatching"
        id="jobMatching"
      >
        <ContentAdditionalInfo
          job={job}
          additionalInfo={candidate.extra_info.additional_information}
          candidate={candidate}
        />
        {(candidate.status === CANDIDATES_STATUSES.interested.value ||
          candidate.status === CANDIDATES_STATUSES.hired.value) && (
          <ContentKeyInfo job={job} candidate={candidate} />
        )}
      </Tabs.Item>
      {/* Career Path */}
      <Tabs.Item label={t('carrer_path')} itemKey="careerPath" id="careerPath">
        <ContentList
          sectionTitle={t('candidate_card.work_experience')}
          sections={workingExperienceItems}
          type="labelValue"
          withLineBreak
        />
        <ContentList
          sectionTitle={t('formal_education')}
          sections={educationItems}
          type="labelValue"
          withLineBreak
        />
      </Tabs.Item>
      {/* Capabilities */}
      <Tabs.Item
        label={t('capabilities')}
        itemKey="capabilities"
        id="capabilities"
      >
        <ContentList
          sections={skillsSectionItems}
          sectionTitle={t('skills')}
          type="pillows"
        />
        <ContentList
          sections={languagesSectionItems}
          sectionTitle={t('languages')}
          type="pillows"
        />
      </Tabs.Item>
      {/* Documents */}
      {(candidate.status === CANDIDATES_STATUSES.hired.value ||
        candidate.status === CANDIDATES_STATUSES.interested.value) && (
        <Tabs.Item label={t('documents')} itemKey="documents" id="documents">
          <ContentDocuments job={job} candidate={candidate} />
        </Tabs.Item>
      )}
    </Tabs>
  );
};

export default CandidateBody;
