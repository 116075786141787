import { pythonAxios, rubyAxios, layer1Axios } from './http';

export const getUserProfileApi = () =>
  layer1Axios({
    method: 'get',
    url: '/v1/users/profile',
  });

export const putUserApi = ({ data }) =>
  layer1Axios({
    method: 'put',
    url: '/v1/users',
    data,
  });

export const getCollaboratorsApi = () =>
  layer1Axios({
    method: 'get',
    url: '/v1/users/collaborators',
  });

export const postUserCollaboratorApi = (data) =>
  layer1Axios({
    method: 'post',
    url: '/v1/users/collaborators',
    data,
  }).then((response) => response.data);

export const deleteCollaboratorApi = ({ id }) =>
  pythonAxios({
    method: 'delete',
    url: `/user/${id}/delete`,
  });

export const updateCollaboratorRoleApi = ({ data = {} } = {}) =>
  rubyAxios({
    method: 'put',
    url: '/users/update_role',
    data,
  });
