import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Icon, Dropdown } from '@lionstep/ui';
import classnames from 'classnames/bind';

// Constants
import { USER_ROLES } from '../../../../../../constants/user.const';

// Styles
import styles from './collaboratorsTable.module.scss';

const cx = classnames.bind(styles);

const CollaboratorsTableRowActions = ({
  collaborator,
  userData,
  onUpdateUserCollaboratorRole,
  onDeleteUserCollaborator,
}) => {
  const { t } = useTranslation();

  const handleUpdateUserCollaboratorRole = () =>
    onUpdateUserCollaboratorRole(
      collaborator,
      collaborator.role === USER_ROLES.admin
        ? USER_ROLES.viewer
        : USER_ROLES.admin,
    );

  const handleDeleteUserCollaborator = () =>
    onDeleteUserCollaborator(collaborator.local_id);

  const renderDropdownTrigger = (props) => (
    <Icon
      name="Settings"
      className={cx(styles.actionIcon, {
        [styles.actionIconActive]: props.isOpen,
      })}
      {...props}
    />
  );

  const CAN_DELETE_COLLABORATOR = userData.email !== collaborator.email;

  return (
    <Dropdown trigger={renderDropdownTrigger}>
      <Dropdown.Item onClick={handleUpdateUserCollaboratorRole}>
        {collaborator.role === USER_ROLES.admin
          ? t('change_to_viewer')
          : t('change_to_admin')}
      </Dropdown.Item>
      {CAN_DELETE_COLLABORATOR && (
        <Dropdown.Item onClick={handleDeleteUserCollaborator}>
          {t('delete_collaborator')}
        </Dropdown.Item>
      )}
    </Dropdown>
  );
};

const CollaboratorsTableRow = ({
  collaborator,
  userData,
  onUpdateUserCollaboratorRole,
  onDeleteUserCollaborator,
}) => {
  const { t } = useTranslation();

  const COLLABORATOR_ROLE =
    collaborator.role === 'Viewer' ? t('Viewer') : t('administrator');
  const COLLABORATOR_COMMENT = !collaborator.confirmed
    ? t('collaborator_page.collaborators.table_pending_invitation')
    : '⎯';

  return (
    <Table.Row>
      <Table.Cell data-testid={`name-${collaborator.id}`}>
        {collaborator.display_name}
      </Table.Cell>
      <Table.Cell data-testid={`email-${collaborator.id}`}>
        {collaborator.email}
      </Table.Cell>
      <Table.Cell data-testid={`role-${collaborator.id}`}>
        {COLLABORATOR_ROLE}
      </Table.Cell>
      <Table.Cell
        className={cx({
          [styles.confirmed]: !collaborator.confirmed,
        })}
        data-testid={`comment-${collaborator.id}`}
      >
        {COLLABORATOR_COMMENT}
      </Table.Cell>
      <Table.Cell textAlign="center" data-testid={`action-${collaborator.id}`}>
        {userData.email !== collaborator.email && (
          <CollaboratorsTableRowActions
            collaborator={collaborator}
            userData={userData}
            onUpdateUserCollaboratorRole={onUpdateUserCollaboratorRole}
            onDeleteUserCollaborator={onDeleteUserCollaborator}
          />
        )}
      </Table.Cell>
    </Table.Row>
  );
};

export default CollaboratorsTableRow;
