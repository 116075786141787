import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Prompt, useLocation } from 'react-router-dom';
import { useForm, useFieldArray, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import download from 'downloadjs';

// Components
import {
  Alert,
  Button,
  Filepicker,
  FormCheckbox,
  FormDatepicker,
  FormEditor,
  FormInput,
  FormRadioButton,
  FormSelectV2,
  FormSelectMultiple,
  FormTextarea,
  Heading,
  Icon,
  Label,
  Grid,
  Spinner,
  Tag,
} from '@lionstep/ui';
import ContentCard from '../../../../components/refactoring/contentCard';
import PageHeading from '../../../../components/refactoring/pageHeading/PageHeading';
import JobVersion from '../JobVersion/JobVersion';
import JobInfoModal from '../JobInfoModal/JobInfoModal';
import FieldInfo from '../../../../components/refactoring/fieldInfo';
import InfoPlusIconWithTooltip from '../../../../components/refactoring/infoPlusIconWithTooltip';
import TamTitles from './components/tamTitles';

// Hooks
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';
import usePrevious from '../../../../hooks/usePrevious';
import useMediaQuery from '../../../../hooks/useMediaQuery';

// Queries
import { useUserQuery } from '../../../../queries/userQueries';
import { useAISearchTitles } from '../../../../queries/jobQueries';
import {
  useCompanyKomboIntegrationQuery,
  useCompanyKomboJobsQuery,
} from '../../../../queries/companyQueries';

// Selectors
import {
  jobFileLoadingSelector,
  jobFileSelector,
  languagesLoadingSelector,
  languagesSelector,
  isLoadingMainLocationsSelector,
  isLoadingAdditionalLocationsSelector,
  locationsSelector,
  isLoadingOptionalSkillsSelector,
  isLoadingMandatorySkillsSelector,
  skillsSelector,
  titlesLoadingSelector,
  titlesSelector,
} from '../../redux/selectors/job.selectors';

// Actions
import {
  clearLocations,
  clearSkills,
  clearTitles,
  getLanguages,
  getLocations,
  getSkills,
  getTitles,
  uploadJobFile,
  deleteJobFile,
} from '../../redux/actions/job.actions';

import { PYTHON_API } from '../../../../config';

import {
  constantsArrayWithTranslatedLabel,
  langShorthand,
  getElementTextContent,
} from '../../../../support';
import {
  calculateBenefitsOptions,
  calculateCustomQuestion,
  calculateIntroduction,
  calculateMessageTemplate,
  calculateSummary,
  calculateTeamDescription,
} from './job.helper';

// Constants
import {
  CONTRACT_TYPE_OPTIONS,
  DIRECT_REPORTS_OPTIONS,
  DISPLAY_HIDE_OPTIONS,
  EMPLOYMENT_RANGE_OPTIONS,
  HOME_DAYS_OPTIONS,
  JOB_APPROACH_OPTIONS,
  JOB_EMPLOYMENT_TYPE_OPTIONS,
  JOB_LANGUAGE_OPTIONS,
  JOB_YES_NO_OPTIONS,
  LANGUAGE_LEVELS_OPTIONS,
  REQUIRE_DESIRED_OPTIONS,
  YEARS_EXPERIENCE_OPTIONS,
  NOTICE_PERIOD_OPTIONS,
  CURRENCIES_OPTIONS,
} from '../../job.constants';
import { API_DATE_FORMAT, DATE_FORMAT } from '../../../../constants/date.const';

// Styles
import styles from './jobForm.module.scss';

const cx = classnames.bind(styles);

const JobForm = ({
  company,
  draftJobSchema,
  job,
  onSubmit,
  publishJobSchema,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isLionstepAdmin = useIsLionstepAdmin();
  const isEditForm = pathname === `/edit-job/${job.id}`;
  const isDuplicateForm = pathname === `/duplicate-job/${job.id}`;
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalType, setInfoModalType] = useState('job_language');
  const versionRef = useRef(null);
  const isPublishValidationRef = useRef(true);
  const { activeBreakpoints } = useMediaQuery();

  const isLoadingTitles = useSelector(titlesLoadingSelector);
  const TITLES_OPTIONS = useSelector(titlesSelector);
  const isLoadingMandatorySkills = useSelector(
    isLoadingMandatorySkillsSelector,
  );
  const isLoadingOptionalSkills = useSelector(isLoadingOptionalSkillsSelector);
  const SKILLS_OPTIONS = useSelector(skillsSelector);
  const isLoadingMainLocations = useSelector(isLoadingMainLocationsSelector);
  const isLoadingAdditionalLocations = useSelector(
    isLoadingAdditionalLocationsSelector,
  );
  const LOCATIONS_OPTIONS = useSelector(locationsSelector);
  const isLoadingLanguages = useSelector(languagesLoadingSelector);
  const LANGUAGES_OPTIONS = useSelector(languagesSelector);
  const jobFile = useSelector(jobFileSelector);
  const jobFileLoading = useSelector(jobFileLoadingSelector);

  // Queries
  const { data: user } = useUserQuery();
  const { data: komboIntegration } = useCompanyKomboIntegrationQuery(
    { id: user.company_id },
    { enabled: false },
  );
  const { data: komboJobs, isFetching: isLoadingKomboJobs } =
    useCompanyKomboJobsQuery({ id: user.company_id });

  // options that needs translating
  const JOB_LANGUAGE_OPTIONS_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    JOB_LANGUAGE_OPTIONS,
  );
  const DISPLAY_HIDE_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    DISPLAY_HIDE_OPTIONS,
  );
  const YES_NO_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    JOB_YES_NO_OPTIONS,
  );
  const JOB_APPROACH_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    JOB_APPROACH_OPTIONS,
  );
  const CONTRACT_TYPES_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    CONTRACT_TYPE_OPTIONS,
  );
  const EMPLOYMENT_TYPES_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    JOB_EMPLOYMENT_TYPE_OPTIONS,
  );
  const REQUIRED_DESIRED_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    REQUIRE_DESIRED_OPTIONS,
  );
  const HOME_DAYS_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    HOME_DAYS_OPTIONS,
  );
  const LANGUAGE_LEVELS_TRANSLATED = constantsArrayWithTranslatedLabel(
    t,
    LANGUAGE_LEVELS_OPTIONS,
  );

  const formMethods = useForm({
    defaultValues: job,
    shouldUnregister: true,
    resolver: (values, context, option) =>
      isPublishValidationRef.current
        ? yupResolver(publishJobSchema)(values, context, option)
        : yupResolver(draftJobSchema)(values, context, option),
  });
  const {
    control,
    formState: { errors, isDirty, isSubmitted },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = formMethods;
  const {
    fields: talentLanguagesFields,
    append: appendTalentLanguages,
    remove: removeTalentLanguages,
  } = useFieldArray({
    control,
    name: 'talent_languages',
  });
  const {
    fields: mandatorySkillsGroupsFields,
    append: appendMandatorySkillGroup,
    remove: removeMandatorySkillGroup,
  } = useFieldArray({
    control,
    name: 'mandatory_skills_groups',
  });
  const watchSummary = watch('summary');
  const watchJobTitle = watch('opening_title');
  const watchCaBeDisclosed = watch('can_be_disclosed');
  const watchEmploymentType = watch('employment_type');
  const watchEmploymentTypeMin = watch('employment_type_min');
  const watchEmploymentTypeMax = watch('employment_type_max');
  const watchLeadershipExperience = watch('leadership_experience');
  const watchManagementRole = watch('management_role');
  const watchTravel = watch('travel');
  const watchFullyRemote = watch('fully_remote');
  const watchLanguage = watch('job_language');
  const watchContractType = watch('contract_type');
  const watchApproach = watch('approach');
  const watchMinExperience = watch('min_experience');
  const watchMaxExperience = watch('max_experience');
  const watchStartDate = watch('start_date');
  const watchEndDate = watch('end_date');
  const watchJobFilePath = watch('job_file_path');
  const watchTitles = watch('titles');

  const languageCode = langShorthand(watchLanguage.value);

  const [benefitsOptions, setBenefitsOptions] = useState(
    calculateBenefitsOptions(company, languageCode, job.benefits_checked),
  );
  useEffect(() => {
    setBenefitsOptions(
      calculateBenefitsOptions(company, languageCode, job.benefits_checked),
    );
  }, [languageCode]);

  const {
    data: suggestedSearchTitles,
    refetch: getSuggestedSearchTitles,
    isFetching: isLoadingSuggestedSearchTitles,
    remove: removeSuggestedSearchTitles,
  } = useAISearchTitles({
    id: job.id,
    language: watchLanguage.value,
    options: { enabled: false },
  });

  const isSuggestedTitleSelected = (title) =>
    watchTitles.find(({ label }) => label === title);

  const onSelectSuggestedTitle = (title) => {
    let newTitles = [];

    if (isSuggestedTitleSelected(title)) {
      newTitles = watchTitles.filter(({ label }) => label !== title);
    } else {
      newTitles = [...watchTitles, { value: title, label: title }];
    }

    setValue('titles', newTitles);
  };

  const handleOnBenefitClick = (e) => {
    const { value, checked } = e.target;

    setBenefitsOptions((currValues) =>
      currValues.map((option) => {
        if (option.value === value) {
          return {
            ...option,
            hide: !checked,
          };
        }

        return option;
      }),
    );
  };

  useEffect(() => {
    if (watchFullyRemote) {
      setValue('homeoffice', false);
    }
  }, [watchFullyRemote]);

  useEffect(() => {
    if (languageCode && watchApproach && !job.message_template) {
      const messageTemplate = calculateMessageTemplate({
        languageCode,
        approach: watchApproach.value,
      });

      setValue('message_template', messageTemplate);
    }
  }, [languageCode, watchApproach]);

  useEffect(() => {
    if (languageCode && watchApproach && !job.introduction) {
      const introduction = calculateIntroduction({
        languageCode,
        approach: watchApproach.value,
        company,
      });
      setValue('introduction', introduction);
    }

    if (languageCode && watchApproach && !job.team_desc) {
      const teamDesc = calculateTeamDescription({
        languageCode,
        approach: watchApproach.value,
      });
      setValue('team_desc', teamDesc);
    }

    if (languageCode && watchApproach && !job.free_question) {
      const customQuestion = calculateCustomQuestion({
        languageCode,
        approach: watchApproach.value,
      });

      setValue('free_question', customQuestion);
    }
  }, [languageCode, watchApproach]);

  useEffect(() => {
    if (languageCode && watchApproach && !job.summary) {
      const summary = calculateSummary({
        languageCode,
        approach: watchApproach.value,
        company: company.company_name,
        title: watchJobTitle || '{title}',
      });
      setValue('summary', summary);
    }
  }, [languageCode, watchApproach, watchJobTitle]);

  useEffect(() => {
    if (jobFile) {
      setValue('job_file_name', jobFile.original_filename);
      setValue(
        'job_file_path',
        `${PYTHON_API}/api/v1/upload/${jobFile.path}/${jobFile.filename}`,
      );
    }
  }, [jobFile]);

  useEffect(() => {
    if (
      watchMinExperience &&
      watchMaxExperience &&
      Number(watchMinExperience.value) > Number(watchMaxExperience.value)
    ) {
      setValue('max_experience', watchMinExperience);
    }
  }, [watchMinExperience]);

  useEffect(() => {
    if (
      watchMinExperience &&
      watchMaxExperience &&
      Number(watchMaxExperience.value) < Number(watchMinExperience.value)
    ) {
      setValue('min_experience', watchMaxExperience);
    }
  }, [watchMaxExperience]);

  useEffect(() => {
    if (
      watchEmploymentTypeMin &&
      watchEmploymentTypeMax &&
      Number(watchEmploymentTypeMin.value.slice(0, -1)) >
        Number(watchEmploymentTypeMax.value.slice(0, -1))
    ) {
      setValue('employment_type_max', watchEmploymentTypeMin);
    }
  }, [watchEmploymentTypeMin]);

  useEffect(() => {
    if (
      watchEmploymentTypeMin &&
      watchEmploymentTypeMax &&
      Number(watchEmploymentTypeMax.value.slice(0, -1)) <
        Number(watchEmploymentTypeMin.value.slice(0, -1))
    ) {
      setValue('employment_type_min', watchEmploymentTypeMax);
    }
  }, [watchEmploymentTypeMax]);

  useEffect(() => {
    if (
      watchStartDate &&
      watchEndDate &&
      moment(watchStartDate, API_DATE_FORMAT).unix() >
        moment(watchEndDate, API_DATE_FORMAT).unix()
    ) {
      setValue('end_date', watchStartDate);
    }
  }, [watchStartDate]);

  useEffect(() => {
    if (
      watchStartDate &&
      watchEndDate &&
      moment(watchEndDate, API_DATE_FORMAT).unix() <
        moment(watchStartDate, API_DATE_FORMAT).unix()
    ) {
      setValue('start_date', watchEndDate);
    }
  }, [watchEndDate]);

  const handleOnTitlesSearch = debounce((value) => {
    if (value && value.length > 2) {
      // adding english always because other langs lack data
      const langs = languageCode !== 'en' ? `${languageCode},en` : languageCode;
      dispatch(getTitles(value, langs));
    }
  }, 500);

  const handleOnTitlesBlur = () => {
    dispatch(clearTitles());
  };

  const handleOnSkillsSearch = debounce((value, skillsType) => {
    if (value && value.length > 2) {
      // adding english always because other langs lack data
      const langs = languageCode !== 'en' ? `${languageCode},en` : languageCode;
      dispatch(getSkills(value, langs, skillsType));
    }
  }, 500);

  const handleOnSkillsBlur = () => {
    dispatch(clearSkills());
  };

  const handleOnLocationsSearch = debounce((value, locationsType) => {
    if (value && value.length > 2) {
      dispatch(getLocations(value, languageCode, locationsType));
    }
  }, 500);

  const handleOnLocationsBlur = () => {
    dispatch(clearLocations());
  };

  const handleOnLanguagesSearch = debounce((value) => {
    if (value && value.length > 2) {
      dispatch(getLanguages(value, languageCode));
    }
  }, 500);

  const handleOnFileUpload = ([file]) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);
      dispatch(uploadJobFile(formData));
    }
  };

  const handleOnFileDelete = () => {
    const fullPathSplitted = getValues('job_file_path').split('/');
    if (!jobFileLoading && fullPathSplitted.length) {
      const filename = fullPathSplitted[fullPathSplitted.length - 1];
      dispatch(deleteJobFile(filename));
      setValue('job_file_name', '');
      setValue('job_file_path', '');
    }
  };

  const handleOnFileDownload = () => {
    const sessionId = localStorage.getItem('sessionId');
    const xhr = new XMLHttpRequest();
    const fileUrl = getValues('job_file_path');
    const fileName = getValues('job_file_name');
    xhr.open('GET', fileUrl, true);
    xhr.setRequestHeader('Authorization', `Basic ${sessionId}`);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.responseType = 'blob';
    xhr.onload = () => {
      download(xhr.response, fileName, 'application/pdf');
    };
    xhr.send();
  };

  const clearLastEmptySkillsGroup = () => {
    const skillGroups = getValues('mandatory_skills_groups');
    if (
      skillGroups.length > 1 &&
      !skillGroups[skillGroups.length - 1]?.group.length
    ) {
      removeMandatorySkillGroup(skillGroups.length - 1);
    }
  };

  const handleOnDraftSave = () => {
    clearLastEmptySkillsGroup();
    isPublishValidationRef.current = false;
    handleSubmit((data) => {
      onSubmit({ data });

      if (isEditForm) {
        versionRef.current.updateVersion();
      }
    })();
  };

  const handleOnPublishOrUpdate = () => {
    clearLastEmptySkillsGroup();
    isPublishValidationRef.current = true;
    handleSubmit((data) => {
      onSubmit({ data, shouldPublish: !job.status, shouldRedirect: true });
      if (isEditForm) {
        versionRef.current.updateVersion();
      }
    })();
  };

  const handleOnVersionCreate = () => {
    clearLastEmptySkillsGroup();
    isPublishValidationRef.current = true;
    handleSubmit((data) => {
      onSubmit({ data, shouldPublish: false, shouldRedirect: false });
      versionRef.current.createVersion();
    })();
  };

  // Info modal
  const handleIsInfoModalOpen = (infoType, oldValue, newValue) => {
    let shouldOpenModal = false;

    if (
      (infoType === 'can_be_disclosed' && oldValue !== newValue) ||
      (infoType === 'job_language' && oldValue.value !== newValue.value) ||
      (infoType === 'approach' && oldValue.value !== newValue.value)
    ) {
      shouldOpenModal = true;
    }

    if (shouldOpenModal) {
      setInfoModalType(infoType);
      setShowInfoModal((currVal) => !currVal);
    }
  };

  const handleOnTalentLanguageAdd = () => {
    let shouldAddEmptyLanguage = true;
    const currentLanguages = getValues('talent_languages');
    if (currentLanguages.length) {
      currentLanguages.forEach((language) => {
        if (!language.language_id || !language.level || !language.importance) {
          shouldAddEmptyLanguage = false;
        }
      });
    }

    if (shouldAddEmptyLanguage) {
      appendTalentLanguages({
        language_id: undefined,
        level: undefined,
        importance: undefined,
      });
    }
  };

  const handleOnMandatorySkillGroupAdd = () => {
    const skillGroups = getValues('mandatory_skills_groups');
    if (skillGroups.every((group) => group.group.length)) {
      appendMandatorySkillGroup({ group: [] });
    }
  };

  const prevApproach = usePrevious(watchApproach);
  const prevLanguage = usePrevious(watchLanguage);
  const prevCanBeDisclosed = usePrevious(watchCaBeDisclosed);

  useEffect(() => {
    if (isEditForm && prevApproach) {
      handleIsInfoModalOpen('approach', prevApproach, watchApproach);
    }
  }, [watchApproach, isEditForm]);

  useEffect(() => {
    if (isEditForm && prevLanguage) {
      handleIsInfoModalOpen('job_language', prevLanguage, watchLanguage);
    }
  }, [watchLanguage, isEditForm]);

  useEffect(() => {
    if (isEditForm && prevCanBeDisclosed) {
      handleIsInfoModalOpen(
        'can_be_disclosed',
        prevCanBeDisclosed,
        watchCaBeDisclosed,
      );
    }
  }, [watchCaBeDisclosed, isEditForm]);

  const [mandatoryGroupFocused, setMandatoryGroupFocused] = useState('');
  const [talentLanguageFocused, setTalentLanguageFocused] = useState('');

  const renderPageTitle = () => {
    if (isDuplicateForm) {
      return t('duplicate_job_page.title');
    }

    if (isEditForm) {
      return t('edit_job');
    }

    return (
      <>
        {t('create_job')} <InfoPlusIconWithTooltip />
      </>
    );
  };

  const renderKomboJobsOption = ({ option }) => (
    <div className={styles.komboJob}>
      <div>{option.label}</div>
      <div className={styles.komboJobLabelSecondary}>
        <strong>ID:</strong> {option.remoteId}
      </div>
      {option.location && (
        <div className={styles.komboJobLabelSecondary}>
          <strong>Location:</strong> {option.location}
        </div>
      )}
    </div>
  );

  const renderKomboPlaceholder = () => {
    let placeholder = 'Select ATS job';

    if (isLoadingKomboJobs) {
      placeholder = 'Loading ATS jobs';
    } else if (!komboIntegration.last_sync_ended_at) {
      placeholder = 'Job syncing in progress';
    }

    return placeholder;
  };

  const ATS_JOBS_OPTIONS = komboJobs?.reduce((acc, komboJob) => {
    if (
      (!isEditForm && komboJob.job_id) ||
      (isEditForm && komboJob.job_id && komboJob.job_id !== job.id)
    ) {
      return acc;
    }

    return [
      ...acc,
      {
        value: komboJob.id,
        location: komboJob.location.raw,
        label: komboJob.name,
        remoteId: komboJob.remote_id,
      },
    ];
  }, []);

  return (
    <Grid.Container className={cx(styles.jobForm)}>
      <Prompt
        when={isEditForm && isDirty && !isSubmitted}
        message={JSON.stringify({
          header: t('job_description_page.prompt.title'),
          content: t('job_description_page.prompt.content'),
          confirmText: t('confirm'),
          cancelText: t('cancel'),
        })}
      />

      <Grid.Row>
        <Grid.Col xs={24}>
          <PageHeading additionalInfo={job?.opening_title}>
            {renderPageTitle()}
          </PageHeading>
        </Grid.Col>
        <Grid.Col xs={24}>
          <FormProvider {...formMethods}>
            <form>
              {/* GENERAL INFORMATION */}
              <ContentCard>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Heading
                      className={styles.jobHeading}
                      id="general"
                      level={3}
                    >
                      {t('job_description_page.general_section.title')}
                    </Heading>
                  </Grid.Col>
                  <Grid.Col xs={24}>
                    <Label
                      className={styles.jobSubHeading}
                      bold
                      required
                      htmlFor="jobFileOrUrl"
                    >
                      Link to (URL) or upload the original job description
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.general_section.job_link.label',
                      )}
                      placeholder={t(
                        'job_description_page.general_section.job_link.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      name="original_url"
                      error={errors.original_url?.message}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <div style={{ display: 'none' }}>
                      <FormInput
                        tabIndex="-1"
                        name="job_file_path"
                        error={errors.job_file?.message}
                        readOnly
                      />
                    </div>

                    <FormInput
                      label={t(
                        'job_description_page.general_section.job_file.label',
                      )}
                      placeholder={t(
                        'job_description_page.general_section.job_file.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      name="job_file_name"
                      error={errors.job_file_name?.message}
                      readOnly
                      disabled={jobFileLoading}
                      suffix={
                        <div className={styles.fileButtons}>
                          {watchJobFilePath && (
                            <>
                              <Icon
                                name="Download"
                                size={24}
                                onClick={handleOnFileDownload}
                                className={styles.downloadFileIcon}
                              />
                              <Icon
                                name="CloseCircle"
                                size={24}
                                onClick={handleOnFileDelete}
                                className={styles.deleteFileIcon}
                              />
                            </>
                          )}
                        </div>
                      }
                      formItemClassName={styles.jobFileInput}
                      actionAddon={
                        <Filepicker
                          accept={{ 'application/pdf': ['.pdf'] }}
                          tabindex="-1"
                          onDropAccepted={handleOnFileUpload}
                          disabled={jobFileLoading}
                        >
                          <Button
                            typeHtml="button"
                            disabled={jobFileLoading}
                            minWidth={false}
                            icon={
                              !activeBreakpoints.includes('lg') ? (
                                <Icon name="Upload" />
                              ) : null
                            }
                            data-testid="upload-file-btn"
                          >
                            {activeBreakpoints.includes('lg') &&
                              t(
                                'job_description_page.general_section.job_link_file.upload_btn',
                              )}
                          </Button>
                        </Filepicker>
                      }
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      label={t(
                        'job_description_page.general_section.job_language.label',
                      )}
                      labelBold
                      name="job_language"
                      error={errors.job_language?.message}
                      options={JOB_LANGUAGE_OPTIONS_TRANSLATED}
                      required
                    />
                    {komboIntegration && (
                      <FormSelectV2
                        label="ATS jobs"
                        placeholder={renderKomboPlaceholder()}
                        searchableWithOpenOnFocus
                        clearable
                        labelBold
                        disabled={
                          komboIntegration.state !== 'ACTIVE' ||
                          !komboIntegration.last_sync_ended_at
                        }
                        name="kombo_job"
                        error={errors.kombo_job?.message}
                        options={ATS_JOBS_OPTIONS || []}
                        onRenderOptionItem={renderKomboJobsOption}
                      />
                    )}
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={JOB_APPROACH_TRANSLATED}
                      name="approach"
                      label={t(
                        'job_description_page.general_section.approach.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.general_section.approach.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      hint={t(
                        'job_description_page.general_section.approach.helptext',
                      )}
                      error={errors.approach?.message}
                      required
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormRadioButton
                      label={t(
                        'job_description_page.general_section.diversity_indicator.label',
                      )}
                      labelBold
                      inputAlign
                      name="diversity"
                      options={DISPLAY_HIDE_TRANSLATED}
                      hint={t(
                        'job_description_page.general_section.diversity_indicator.helptext',
                      )}
                      renderTemplate={(diversityElements) => (
                        <Grid.Row gutter={30}>
                          {diversityElements.map((diveristyElement) => (
                            <Grid.Col
                              xs="auto"
                              key={diveristyElement.props.value}
                            >
                              {diveristyElement}
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      )}
                      error={errors.diversity?.message}
                      spacing={{ margin: '0.5rem' }}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      bold
                      className={styles.jobSubHeading}
                      htmlFor="jobVideo"
                    >
                      Job video
                    </Label>
                  </Grid.Col>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.summary_section.job_video.placeholder',
                      )}
                      name="job_video"
                      placeholder={t(
                        'job_description_page.summary_section.job_video.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      hint={t(
                        'job_description_page.summary_section.job_video.helptext',
                      )}
                      error={errors.job_video?.message}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.summary_section.job_video_tagline.label',
                      )}
                      placeholder={t(
                        'job_description_page.summary_section.job_video_tagline.placeholder',
                      )}
                      hint={t(
                        'job_description_page.summary_section.job_video_tagline.helptext',
                      )}
                      name="job_video_caption"
                      error={errors.job_video_caption?.message}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.summary_section.introduction_paragraph.label',
                      )}
                      labelBold
                      required
                      placeholder={t(
                        'job_description_page.summary_section.introduction_paragraph.placeholder',
                        { lng: languageCode },
                      )}
                      hint={t(
                        'job_description_page.summary_section.introduction_paragraph.helptext',
                      )}
                      name="introduction"
                      error={errors.introduction?.message}
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormTextarea
                      label={t(
                        'company_page.displayed_section.first_linkedin_message.label',
                      )}
                      labelBold
                      placeholder={t(
                        'company_page.displayed_section.first_linkedin_message.placeholder',
                        { lng: languageCode },
                      )}
                      name="message_template"
                      error={errors.message_template?.message}
                      readOnly={!isLionstepAdmin}
                      spacing={{ margin: 0 }}
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>
              </ContentCard>

              {/* JOB DETAILS */}
              <ContentCard>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Heading
                      level={3}
                      id="summary"
                      className={styles.jobHeading}
                    >
                      {t('job_description_page.summary_section.title')}
                    </Heading>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.general_section.job_title.label',
                      )}
                      labelBold
                      name="opening_title"
                      error={errors.opening_title?.message}
                      required
                    />

                    {!errors.opening_title?.message &&
                      watchJobTitle.length > 40 && (
                        <FieldInfo
                          info={t(
                            'job_description_page.general_section.job_title.warning',
                          )}
                        />
                      )}
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.summary_section.summary.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.summary_section.summary.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      hint={t(
                        'job_description_page.summary_section.summary.helptext',
                      )}
                      name="summary"
                      error={errors.summary?.message}
                      required
                      collapsable
                    />

                    {!errors.summary?.message &&
                      getElementTextContent(watchSummary).length > 1000 && (
                        <FieldInfo
                          info={t(
                            'job_description_page.summary_section.summary.warning',
                          )}
                        />
                      )}
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.summary_section.your_role.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.summary_section.your_role.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      name="your_role"
                      error={errors.your_role?.message}
                      required
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.requirements_section.must_have.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.requirements_section.must_have.placeholder',
                        { lng: languageCode },
                      )}
                      name="requirements"
                      error={errors.requirements?.message}
                      required
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.requirements_section.nice_to_have.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.requirements_section.nice_to_have.placeholder',
                        { lng: languageCode },
                      )}
                      name="nice_to_have"
                      error={errors.nice_to_have?.message}
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="talent_languages"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.requirements_section.languages.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                {talentLanguagesFields.map((field, index) => (
                  <Grid.Row
                    key={field.id}
                    gutter={{
                      xs: 15,
                      md: 0,
                    }}
                    className={styles.talentLanguageRow}
                  >
                    <Grid.Col xs={21} sm={22} md={24}>
                      <Grid.Row gutter={30}>
                        <Grid.Col xs={24} md={8}>
                          <FormSelectV2
                            name={`talent_languages.${index}.language_id`}
                            label={t(
                              'job_description_page.requirements_section.languages.language',
                            )}
                            placeholder={t(
                              'job_description_page.requirements_section.languages.choose_language',
                              { lng: languageCode },
                            )}
                            searchable
                            error={
                              errors.talent_languages?.[index]?.language_id
                                ?.message
                            }
                            isLoading={
                              index === talentLanguageFocused &&
                              isLoadingLanguages
                            }
                            options={LANGUAGES_OPTIONS}
                            onSearch={handleOnLanguagesSearch}
                            onFocus={() => setTalentLanguageFocused(index)}
                            formItemClassName={styles.talentLanguageSelect}
                          />
                        </Grid.Col>
                        <Grid.Col xs={24} md={8}>
                          <FormSelectV2
                            name={`talent_languages.${index}.level`}
                            options={LANGUAGE_LEVELS_TRANSLATED}
                            label={t(
                              'job_description_page.requirements_section.languages.level',
                            )}
                            placeholder={t(
                              'job_description_page.requirements_section.languages.choose_level',
                              { lng: languageCode },
                            )}
                            error={
                              errors.talent_languages?.[index]?.level?.message
                            }
                            formItemClassName={styles.talentLevelSelect}
                          />
                        </Grid.Col>
                        <Grid.Col xs={24} md={8}>
                          <FormSelectV2
                            name={`talent_languages.${index}.importance`}
                            options={REQUIRED_DESIRED_TRANSLATED}
                            label={t(
                              'job_description_page.requirements_section.languages.importance',
                            )}
                            placeholder={t(
                              'job_description_page.requirements_section.languages.choose_importance',
                              { lng: languageCode },
                            )}
                            error={
                              errors.talent_languages?.[index]?.importance
                                ?.message
                            }
                            actionAddon={
                              <div className={cx(styles.removeLanguageWrapper)}>
                                <Icon
                                  name="CloseCircle"
                                  onClick={() => removeTalentLanguages(index)}
                                  className={styles.removeLanguageIcon}
                                />
                              </div>
                            }
                            formItemClassName={styles.talentImportanceSelect}
                          />
                        </Grid.Col>
                      </Grid.Row>
                    </Grid.Col>
                    <Grid.Col
                      xs={3}
                      sm={2}
                      className={styles.mobileLanguagesClearSection}
                    >
                      <div className={styles.mobileLanguagesClearLine} />
                      <span className={styles.mobileRemoveLanguage}>
                        <Icon
                          name="CloseCircle"
                          onClick={() => removeTalentLanguages(index)}
                          className={styles.mobileRemoveLanguageIcon}
                          size={33}
                        />
                      </span>
                    </Grid.Col>
                  </Grid.Row>
                ))}
                <Grid.Row>
                  <Grid.Col
                    xs={24}
                    style={{ paddingBottom: '48px', paddingTop: '14px' }}
                  >
                    <Button
                      typeHtml="button"
                      onClick={handleOnTalentLanguageAdd}
                      icon={<Icon name="PlusCircle" />}
                      ghost
                      data-testid="add-talent-lang-btn"
                    >
                      {t(
                        'job_description_page.requirements_section.languages.add_language_btn',
                      )}
                    </Button>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row gutter={20}>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.meta_section.custom_question.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.meta_section.custom_question.placeholder',
                        { lng: languageCode },
                      )}
                      name="free_question"
                      error={errors.free_question?.message}
                      max={10000}
                      collapsable
                    />
                  </Grid.Col>
                  {/* Salary */}
                  <Grid.Col xs={24}>
                    <Label className={styles.jobSubHeading} bold>
                      {t('salary')}
                    </Label>
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormInput
                      error={errors.base_salary_min?.message}
                      label={t('minimum_per_year')}
                      name="base_salary_min"
                      type="number"
                      min={10000}
                      step={1000}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormInput
                      error={errors.base_salary_max?.message}
                      label={t('maximum_per_year')}
                      name="base_salary_max"
                      type="number"
                      min={10000}
                      step={1000}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      error={errors.currency?.message}
                      label={t('currency')}
                      name="currency"
                      options={CURRENCIES_OPTIONS}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24}>
                    <FormInput
                      error={errors.bonus?.message}
                      label={t('job_description_page.meta_section.bonus.label')}
                      name="bonus"
                      placeholder={t(
                        'job_description_page.meta_section.bonus.placeholder',
                      )}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.meta_section.questionnaire.label',
                      )}
                      labelBold
                      name="questionnaire"
                      error={errors.questionnaire?.message}
                      max={10000}
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormSelectMultiple
                      autocomplete
                      creatable
                      isLoading={isLoadingTitles}
                      label={t(
                        'job_description_page.meta_section.search_titles.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.meta_section.search_titles.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      name="titles"
                      onBlur={handleOnTitlesBlur}
                      onSearch={handleOnTitlesSearch}
                      options={TITLES_OPTIONS}
                      showTags
                      tagsPosition="bottom"
                      error={errors.titles?.message}
                      noResultsText={t(
                        'job_description_page.multiselects.create_option',
                      )}
                      spacing={{ padding: '1rem', margin: '6px' }}
                    />
                  </Grid.Col>
                  {isEditForm && (
                    <Grid.Col xs={24}>
                      <div className={styles.aiTitlesSection}>
                        <Button
                          ghost
                          icon={<Icon name="Sparkle" />}
                          iconPosition="left"
                          onClick={() => {
                            removeSuggestedSearchTitles();
                            getSuggestedSearchTitles();
                          }}
                          disabled={isLoadingSuggestedSearchTitles}
                        >
                          {t(
                            'job_description_page.meta_section.search_titles.get_suggestions.btn',
                          )}
                        </Button>
                        {isLoadingSuggestedSearchTitles && (
                          <Spinner position="absolute" size={50} />
                        )}
                        {!isLoadingSuggestedSearchTitles &&
                          suggestedSearchTitles?.length && (
                            <div>
                              {suggestedSearchTitles.map((title) => (
                                <Tag
                                  key={title}
                                  className={cx(styles.titleSuggestion, {
                                    [styles.selected]:
                                      isSuggestedTitleSelected(title),
                                  })}
                                  closable={false}
                                  iconName={
                                    isSuggestedTitleSelected(title)
                                      ? 'Check'
                                      : ''
                                  }
                                  iconClassName={styles.check}
                                  onClick={() => onSelectSuggestedTitle(title)}
                                >
                                  {title}
                                </Tag>
                              ))}
                            </div>
                          )}
                        {suggestedSearchTitles && (
                          <Alert type="info">
                            <span className={styles.alertTitle}>
                              {t(
                                'job_description_page.meta_section.search_titles.get_suggestions.alert_header',
                              )}
                            </span>
                            <span>
                              {t(
                                'job_description_page.meta_section.search_titles.get_suggestions.alert_description',
                              )}
                            </span>
                          </Alert>
                        )}
                      </div>
                    </Grid.Col>
                  )}
                  {isLionstepAdmin && !!job.titles_tam?.length && (
                    <Grid.Col xs={24}>
                      <TamTitles tamTitles={job.titles_tam} />
                    </Grid.Col>
                  )}
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="candidate_experince"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.meta_section.candidate_experince.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={12} md={8}>
                    <FormSelectV2
                      options={YEARS_EXPERIENCE_OPTIONS}
                      name="min_experience"
                      label={t(
                        'job_description_page.meta_section.candidate_experince.min',
                      )}
                      placeholder={t(
                        'job_description_page.meta_section.candidate_experince.choose_num_of_years',
                        { lng: languageCode },
                      )}
                      error={errors.min_experience?.message}
                      required
                    />
                  </Grid.Col>
                  <Grid.Col xs={12} md={8}>
                    <FormSelectV2
                      options={YEARS_EXPERIENCE_OPTIONS}
                      name="max_experience"
                      label={t(
                        'job_description_page.meta_section.candidate_experince.max',
                      )}
                      placeholder={t(
                        'job_description_page.meta_section.candidate_experince.choose_num_of_years',
                        { lng: languageCode },
                      )}
                      error={errors.max_experience?.message}
                      required
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="mandatory_skills_groups"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.meta_section.must_have_skills.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                {mandatorySkillsGroupsFields.map((field, index) => (
                  <Grid.Row key={field.id}>
                    <Grid.Col xs={24}>
                      <FormSelectMultiple
                        autocomplete
                        creatable
                        name={`mandatory_skills_groups.${index}.group`}
                        label={t(
                          'job_description_page.meta_section.must_have_skills.at_least_one_of',
                        )}
                        placeholder={t(
                          'job_description_page.meta_section.must_have_skills.placeholder',
                          { lng: languageCode },
                        )}
                        error={
                          errors.mandatory_skills_groups?.[index]?.group
                            ?.message
                        }
                        isLoading={
                          index === mandatoryGroupFocused &&
                          isLoadingMandatorySkills
                        }
                        options={SKILLS_OPTIONS}
                        onBlur={handleOnSkillsBlur}
                        onClearItem={(items) => {
                          if (!items.length && index > 0) {
                            removeMandatorySkillGroup(index);
                          }
                        }}
                        onSearch={handleOnSkillsSearch}
                        showTags
                        tagsPosition="top"
                        onFocus={() => setMandatoryGroupFocused(index)}
                        spacing={{ margin: 0 }}
                        noResultsText={t(
                          'job_description_page.multiselects.create_option',
                        )}
                      />
                    </Grid.Col>
                  </Grid.Row>
                ))}
                <Grid.Row>
                  <Grid.Col
                    xs={24}
                    style={{ paddingBottom: '48px', paddingTop: '14px' }}
                  >
                    <Button
                      onClick={handleOnMandatorySkillGroupAdd}
                      ghost
                      icon={<Icon name="PlusCircle" />}
                      data-testid="add-skill-group-btn"
                    >
                      {t(
                        'job_description_page.meta_section.must_have_skills.add_skill_group_btn',
                      )}
                    </Button>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormSelectMultiple
                      autocomplete
                      creatable
                      isLoading={isLoadingOptionalSkills}
                      label={t(
                        'job_description_page.meta_section.nice_to_have_skills.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.meta_section.nice_to_have_skills.placeholder',
                        { lng: languageCode },
                      )}
                      name="optional_skills"
                      onBlur={handleOnSkillsBlur}
                      onSearch={(value) =>
                        handleOnSkillsSearch(value, 'optional')
                      }
                      options={SKILLS_OPTIONS}
                      showTags
                      tagsPosition="top"
                      error={errors.optional_skills?.message}
                      noResultsText={t(
                        'job_description_page.multiselects.create_option',
                      )}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="benefits_pool_selected"
                      bold
                      className={styles.jobSubHeading}
                      hint={t(
                        'job_description_page.benefits_section.benefits.helptext',
                      )}
                    >
                      {t(
                        'job_description_page.benefits_section.benefits.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    {benefitsOptions.every((option) => option.hide) && (
                      <span className={styles.noBenefitSelectedInfo}>
                        {t(
                          'job_description_page.benefits_section.benefits.no_benefits_selected',
                        )}
                      </span>
                    )}
                    <FormCheckbox
                      name="benefits_checked"
                      options={benefitsOptions}
                      renderTemplate={(elements) => (
                        <Grid.Row gutter={[30, 10]}>
                          {elements.map(
                            (element) =>
                              !element.props.hide && (
                                <Grid.Col
                                  xs={24}
                                  md={12}
                                  xl={8}
                                  key={element.props.value}
                                >
                                  {element}
                                </Grid.Col>
                              ),
                          )}
                        </Grid.Row>
                      )}
                      onClick={handleOnBenefitClick}
                    />
                  </Grid.Col>
                </Grid.Row>

                {!!benefitsOptions.find((option) => option.hide) && (
                  <>
                    <Grid.Row>
                      <Grid.Col xs={24}>
                        <Label
                          className={styles.jobSubHeading}
                          htmlFor="additionalBenefits"
                        >
                          {t(
                            'job_description_page.benefits_section.benefits.additonal_options',
                          )}
                        </Label>
                      </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Col xs={24}>
                        <FormCheckbox
                          name="benefits_checked"
                          options={benefitsOptions}
                          renderTemplate={(elements) => (
                            <Grid.Row gutter={[30, 10]}>
                              {elements.map(
                                (element) =>
                                  element.props.hide && (
                                    <Grid.Col
                                      xs={24}
                                      md={12}
                                      xl={8}
                                      key={element.props.value}
                                    >
                                      {element}
                                    </Grid.Col>
                                  ),
                              )}
                            </Grid.Row>
                          )}
                          spacing={{ margin: '1rem' }}
                          onClick={handleOnBenefitClick}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </>
                )}

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.benefits_section.additional_bonus.label',
                      )}
                      placeholder={t(
                        'job_description_page.benefits_section.additional_bonus.placeholder',
                        { lng: languageCode },
                      )}
                      hint={t(
                        'job_description_page.benefits_section.additional_bonus.helptext',
                      )}
                      name="additional_benefits"
                      error={errors.additional_benefits?.message}
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormRadioButton
                      label={t(
                        'job_description_page.meta_section.disclose_company.label',
                      )}
                      labelBold
                      name="can_be_disclosed"
                      inputAlign
                      options={YES_NO_TRANSLATED}
                      renderTemplate={(yesNoElements) => (
                        <Grid.Row gutter={30}>
                          {yesNoElements.map((yesNoEl) => (
                            <Grid.Col key={yesNoEl.props.value}>
                              {yesNoEl}
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      )}
                      spacing={{ margin: '14px' }}
                      error={errors.can_be_disclosed?.message}
                    />
                  </Grid.Col>
                </Grid.Row>

                {watchCaBeDisclosed === 'no' && (
                  <Grid.Row>
                    <Grid.Col xs={24}>
                      <FormInput
                        label={t(
                          'job_description_page.meta_section.replacement_name.label',
                        )}
                        placeholder={t(
                          'job_description_page.meta_section.replacement_name.placeholder',
                          { lng: languageCode },
                        )}
                        name="replacement_name"
                        error={errors.replacement_name?.message}
                        disabled={watchCaBeDisclosed === 'yes'}
                      />
                    </Grid.Col>
                  </Grid.Row>
                )}

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="locations"
                      bold
                      className={styles.jobSubHeading}
                    >
                      Locations
                    </Label>
                  </Grid.Col>
                  <Grid.Col xs={24}>
                    <FormSelectV2
                      searchable
                      isLoading={isLoadingMainLocations}
                      name="job_location"
                      label={t(
                        'job_description_page.general_section.office_location.label',
                      )}
                      placeholder={t(
                        'job_description_page.general_section.office_location.placeholder',
                        { lng: languageCode },
                      )}
                      error={errors.job_location?.message}
                      onSearch={handleOnLocationsSearch}
                      options={LOCATIONS_OPTIONS}
                      required
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormSelectMultiple
                      autocomplete
                      isLoading={isLoadingAdditionalLocations}
                      label={t(
                        'job_description_page.general_section.additional_locations.label',
                      )}
                      placeholder={t(
                        'job_description_page.general_section.additional_locations.placeholder',
                        { lng: languageCode },
                      )}
                      name="additional_locations"
                      error={errors.additional_locations?.message}
                      onBlur={handleOnLocationsBlur}
                      onSearch={(value) =>
                        handleOnLocationsSearch(value, 'additional')
                      }
                      options={LOCATIONS_OPTIONS}
                      showTags
                      tagsPosition="top"
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row gutter={30}>
                  <Grid.Col xs={24}>
                    <Label
                      bold
                      className={styles.jobSubHeading}
                      htmlFor="contractDetails"
                    >
                      Contract Details
                    </Label>
                  </Grid.Col>

                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={CONTRACT_TYPES_TRANSLATED}
                      name="contract_type"
                      label={t(
                        'job_description_page.requirements_section.contract_type.label',
                      )}
                      placeholder={t(
                        'job_description_page.requirements_section.contract_type.placeholder',
                        { lng: languageCode },
                      )}
                      error={errors.contract_type?.message}
                    />
                  </Grid.Col>

                  <Grid.Col xs={24} md={8}>
                    <FormDatepicker
                      name="start_date"
                      label="Start Date"
                      displayFormat={DATE_FORMAT}
                      placeholder={t(
                        'job_description_page.requirements_section.contract_type.choose_start_date',
                        { lng: languageCode },
                      )}
                      error={errors.start_date?.message}
                    />
                  </Grid.Col>

                  <Grid.Col xs={24} md={8}>
                    <FormDatepicker
                      name="end_date"
                      label="End Date"
                      displayFormat={DATE_FORMAT}
                      placeholder={t(
                        'job_description_page.requirements_section.contract_type.choose_end_date',
                        { lng: languageCode },
                      )}
                      error={errors.end_date?.message}
                      disabled={
                        watchContractType.value === 'Unlimited' ||
                        watchContractType.value === null
                      }
                    />
                  </Grid.Col>

                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={NOTICE_PERIOD_OPTIONS}
                      name="notice_period"
                      label="Max. acceptable notice period"
                      placeholder="Please select"
                      error={errors.notice_period?.message}
                      required
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="employment_details"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.general_section.employment_details.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24} md={8}>
                    <FormRadioButton
                      label={t(
                        'job_description_page.general_section.employment_details.type',
                      )}
                      name="employment_type"
                      options={EMPLOYMENT_TYPES_TRANSLATED}
                      inputAlign
                      renderTemplate={(employmentElements) => (
                        <Grid.Row gutter={30}>
                          {employmentElements.map((employmentElement) => (
                            <Grid.Col key={employmentElement.props.value}>
                              {employmentElement}
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      )}
                      error={errors.employment_type?.message}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={16}>
                    <Grid.Row gutter={30}>
                      <Grid.Col xs={12}>
                        <FormSelectV2
                          options={EMPLOYMENT_RANGE_OPTIONS}
                          name="employment_type_min"
                          label={t(
                            'job_description_page.general_section.employment_details.from',
                          )}
                          placeholder="00%"
                          error={errors.employment_type_min?.message}
                          disabled={watchEmploymentType === 'fulltime'}
                        />
                      </Grid.Col>

                      <Grid.Col xs={12}>
                        <FormSelectV2
                          options={EMPLOYMENT_RANGE_OPTIONS}
                          name="employment_type_max"
                          label={t(
                            'job_description_page.general_section.employment_details.up_to',
                          )}
                          placeholder="00%"
                          error={errors.employment_type_max?.message}
                          disabled={watchEmploymentType === 'fulltime'}
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="prev_leadership"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.requirements_section.prev_leadership.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24} md={8}>
                    <FormRadioButton
                      label={t(
                        'job_description_page.requirements_section.prev_leadership.management_experience',
                      )}
                      name="leadership_experience"
                      options={YES_NO_TRANSLATED}
                      inputAlign
                      renderTemplate={(leadershipElements) => (
                        <Grid.Row gutter={30}>
                          {leadershipElements.map((leadershipElement) => (
                            <Grid.Col key={leadershipElement.props.value}>
                              {leadershipElement}
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      )}
                      error={errors.leadership_experience?.message}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={REQUIRED_DESIRED_TRANSLATED}
                      name="leadership_importance"
                      label={t(
                        'job_description_page.requirements_section.prev_leadership.importance',
                      )}
                      placeholder={t(
                        'job_description_page.requirements_section.prev_leadership.placeholder',
                        { lng: languageCode },
                      )}
                      error={errors.leadership_importance?.message}
                      disabled={watchLeadershipExperience === 'no'}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="management_role"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.summary_section.management_role.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24} md={8}>
                    <FormRadioButton
                      label={t(
                        'job_description_page.summary_section.management_role.management_role',
                      )}
                      name="management_role"
                      options={YES_NO_TRANSLATED}
                      inputAlign
                      renderTemplate={(roleElements) => (
                        <Grid.Row gutter={30}>
                          {roleElements.map((roleElement) => (
                            <Grid.Col key={roleElement.props.value}>
                              {roleElement}
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      )}
                      error={errors.management_role?.message}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={DIRECT_REPORTS_OPTIONS}
                      name="peopleToManage"
                      label={t(
                        'job_description_page.summary_section.management_role.will_manage',
                      )}
                      placeholder={t(
                        'job_description_page.summary_section.management_role.placeholder',
                        { lng: languageCode },
                      )}
                      error={errors.peopleToManage?.message}
                      disabled={watchManagementRole === 'no'}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="need_to_travel"
                      className={styles.jobSubHeading}
                      bold
                    >
                      {t(
                        'job_description_page.requirements_section.need_to_travel.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24} md={8}>
                    <FormRadioButton
                      label={t(
                        'job_description_page.requirements_section.need_to_travel.travel',
                      )}
                      name="travel"
                      options={YES_NO_TRANSLATED}
                      inputAlign
                      renderTemplate={(travelElements) => (
                        <Grid.Row gutter={30}>
                          {travelElements.map((travelElement) => (
                            <Grid.Col key={travelElement.props.value}>
                              {travelElement}
                            </Grid.Col>
                          ))}
                        </Grid.Row>
                      )}
                      error={errors.travel?.message}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={REQUIRED_DESIRED_TRANSLATED}
                      name="travel_importance"
                      label={t(
                        'job_description_page.requirements_section.need_to_travel.travel_importance',
                      )}
                      placeholder={t(
                        'job_description_page.requirements_section.need_to_travel.placeholder',
                        { lng: languageCode },
                      )}
                      error={errors.travel_importance?.message}
                      disabled={watchTravel === 'no'}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.requirements_section.need_to_travel.details',
                      )}
                      placeholder={t(
                        'job_description_page.requirements_section.need_to_travel.travel_details',
                        { lng: langShorthand(job.job_language.mid) },
                      )}
                      name="travel_details"
                      error={errors.travel_details?.message}
                      disabled={watchTravel === 'no'}
                    />
                  </Grid.Col>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Label
                      bold
                      className={styles.jobSubHeading}
                      htmlFor="employmentOptions"
                    >
                      {t(
                        'job_description_page.general_section.employment_options.label',
                      )}
                    </Label>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24} md={8}>
                    <FormCheckbox
                      name="freelance"
                      options={[
                        {
                          label: t(
                            'job_description_page.general_section.employment_options.freelance_possible',
                          ),
                        },
                      ]}
                      error={errors.freelance?.message}
                      inputAlign
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormCheckbox
                      name="fully_remote"
                      options={[
                        {
                          label: t(
                            'job_description_page.general_section.employment_options.fully_remote',
                          ),
                        },
                      ]}
                      error={errors.fully_remote?.message}
                      inputAlign
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormSelectV2
                      options={HOME_DAYS_TRANSLATED}
                      name="home_days"
                      label="Home Office"
                      placeholder={t(
                        'job_description_page.general_section.employment_options.home_days',
                        { lng: languageCode },
                      )}
                      error={errors.home_days?.message}
                      disabled={watchFullyRemote}
                      spacing={{ margin: 0, padding: 0 }}
                    />
                  </Grid.Col>
                </Grid.Row>
              </ContentCard>
              <ContentCard>
                {/* TEAM */}
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Heading level={3} id="team" className={styles.jobHeading}>
                      {t('job_description_page.team_section.title')}
                    </Heading>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormEditor
                      label={t(
                        'job_description_page.team_section.team_desc.label',
                      )}
                      labelBold
                      placeholder={t(
                        'job_description_page.team_section.team_desc.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      hint={t(
                        'job_description_page.team_section.team_desc.helptext',
                      )}
                      name="team_desc"
                      error={errors.team_desc?.message}
                      collapsable
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24}>
                    <Label
                      htmlFor="team_characteristics"
                      bold
                      className={styles.jobSubHeading}
                    >
                      {t(
                        'job_description_page.team_section.team_characteristics.label',
                      )}
                    </Label>
                  </Grid.Col>
                  <Grid.Col xs={24}>
                    <FormInput
                      placeholder={t(
                        'job_description_page.team_section.team_characteristics.reports_to.placeholder',
                        { lng: languageCode },
                      )}
                      label="Reports to"
                      name="superior_role"
                      error={errors.superior_role?.message}
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row gutter={30}>
                  <Grid.Col xs={24} md={8}>
                    <FormInput
                      placeholder={t(
                        'job_description_page.team_section.team_characteristics.choose_language',
                        { lng: languageCode },
                      )}
                      label="Team language"
                      name="team_language"
                      error={errors.team_language?.message}
                      formItemClassName={styles.spacingRemoveTeamLanguage}
                    />
                  </Grid.Col>
                  <Grid.Col xs={24} md={8}>
                    <FormInput
                      placeholder={t(
                        'job_description_page.team_section.team_characteristics.team_size.placeholder',
                        { lng: languageCode },
                      )}
                      label="Team size"
                      name="team_size"
                      error={errors.team_size?.message}
                      formItemClassName={styles.spacingRemoveTeamSize}
                    />
                  </Grid.Col>
                </Grid.Row>
              </ContentCard>
              <ContentCard>
                {/* BACKGROUND INFORMATION */}
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <Heading level={3} id="meta" className={styles.jobHeading}>
                      {t('job_description_page.meta_section.title')}
                    </Heading>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.meta_section.contact_person.label',
                      )}
                      placeholder={t(
                        'job_description_page.meta_section.contact_person.placeholder',
                        {
                          lng: languageCode,
                        },
                      )}
                      name="contact_person"
                      error={errors.contact_person?.message}
                      required
                    />
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <FormInput
                      label={t(
                        'job_description_page.meta_section.contact_person_title.label',
                      )}
                      name="contact_person_title"
                      error={errors.contact_person_title?.message}
                      required
                      spacing={{ margin: 0 }}
                    />
                  </Grid.Col>
                </Grid.Row>
              </ContentCard>

              {isLionstepAdmin && isEditForm && (
                <Grid.Row>
                  <Grid.Col xs={24}>
                    <JobVersion ref={versionRef} jobId={job.id} />
                  </Grid.Col>
                </Grid.Row>
              )}

              {isLionstepAdmin && (
                <Grid.Row>
                  <Grid.Col xs={24}>
                    {(!job.status || isDuplicateForm) && (
                      <Button
                        onClick={handleOnDraftSave}
                        type="primary"
                        ghost
                        className={styles.actionButtons}
                        data-testid="save-draft-job-btn"
                      >
                        {isDuplicateForm
                          ? t('duplicate_job_page.duplicate_btn_text')
                          : t('job_description_page.save_job_draft')}
                      </Button>
                    )}

                    {!isDuplicateForm && (
                      <Button
                        onClick={handleOnPublishOrUpdate}
                        type="primary"
                        className={styles.actionButtons}
                        data-testid="publish-job-btn"
                      >
                        {job.status
                          ? t('job_description_page.update_job')
                          : t('job_description_page.publish_job')}
                      </Button>
                    )}

                    {!isDuplicateForm && job.status && (
                      <Button
                        onClick={handleOnVersionCreate}
                        type="primary"
                        className={styles.actionButtons}
                        ghost
                        data-testid="create-version-btn"
                      >
                        {t('job.versions.current.create_new_btn')}
                      </Button>
                    )}
                  </Grid.Col>
                </Grid.Row>
              )}
            </form>
          </FormProvider>
        </Grid.Col>
      </Grid.Row>

      <JobInfoModal
        show={showInfoModal}
        infoModalType={infoModalType}
        onClose={() => setShowInfoModal(false)}
      />
    </Grid.Container>
  );
};

JobForm.propTypes = {
  company: PropTypes.shape().isRequired,
  draftJobSchema: PropTypes.shape().isRequired,
  job: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func.isRequired,
  publishJobSchema: PropTypes.shape().isRequired,
};

export default JobForm;
