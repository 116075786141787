/* eslint-disable import/prefer-default-export */

export const getJobCollaborators = ({
  user,
  job,
  isLionstepAdmin,
  collaborators,
}) => {
  const isLionstepDomain = user.email.includes('@lionstep.com');

  const isDifferentUser = (collaborator) =>
    collaborator.local_id !== user.local_id;

  const isNotOnJob = (collaborator) =>
    !job?.users.includes(collaborator.local_id);
  const isOnJob = (collaborator) => job?.users.includes(collaborator.local_id);

  const isLionstepAdminAndNotCurrentUser = (collaborator) =>
    isLionstepAdmin && isDifferentUser(collaborator);

  const isNotLionstepUserAndNotCurrentUser = (collaborator) =>
    isDifferentUser(collaborator) && !isLionstepDomain;

  const safeToShowCollaborators = collaborators.filter(
    (collaborator) =>
      isLionstepAdminAndNotCurrentUser(collaborator) ||
      isNotLionstepUserAndNotCurrentUser(collaborator),
  );

  const collaboratorsNotOnJob = safeToShowCollaborators.filter(isNotOnJob);
  const collaboratorsOnJob = safeToShowCollaborators.filter(isOnJob);

  return { collaboratorsNotOnJob, collaboratorsOnJob };
};
