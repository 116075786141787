import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal, Spinner, Tabs } from '@lionstep/ui';

// Components
import InviteExistingCollaborator from './InviteExistingCollaborator/InviteExistingCollaborator';
import AddNewCollaborator from './AddNewCollaborator/AddNewCollaborator';
import CollaboratorsTable from './CollaboratorsTable/CollaboratorsTable';

// Selectors
import { isLoadingAddRemoveCollaboratorSelector } from '../../../../redux/selectors/dashboard.selectors';

// Helpers
import { getJobCollaborators } from './jobShareModalHelpers';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Queries
import {
  useUserQuery,
  useUserCollaborators,
} from '../../../../../../queries/userQueries';

// Styles
import styles from './jobShareModal.module.scss';

const JobShareModal = ({ job, show, onClose }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();

  const { data: user } = useUserQuery();
  const { data: collaborators } = useUserCollaborators({
    options: { enabled: false },
  });

  const isLoadingAddRemoveCollaborator = useSelector(
    isLoadingAddRemoveCollaboratorSelector,
  );

  const { collaboratorsNotOnJob, collaboratorsOnJob } = getJobCollaborators({
    user,
    job,
    isLionstepAdmin,
    collaborators,
  });

  return (
    <Modal show={show} onClose={onClose} className={styles.modal}>
      {isLoadingAddRemoveCollaborator && (
        <Spinner position={'absolute'} size={100} />
      )}
      <Modal.Header>{t('share_your_job')}</Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <Tabs tabScrollerId="share-your-job">
          <Tabs.Item
            label="Invite existing collaborator"
            itemKey="invite-existing-collaborator"
          >
            <InviteExistingCollaborator
              job={job}
              collaborators={collaborators}
              collaboratorsNotOnJob={collaboratorsNotOnJob}
            />
            <CollaboratorsTable
              job={job}
              collaboratorsOnJob={collaboratorsOnJob}
            />
          </Tabs.Item>
          <Tabs.Item label="Add new viewer" itemKey="add-new-viewer">
            <AddNewCollaborator job={job} />
            <CollaboratorsTable
              job={job}
              collaboratorsOnJob={collaboratorsOnJob}
            />
          </Tabs.Item>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

JobShareModal.defaultProps = {
  job: {},
};

JobShareModal.propTypes = {
  job: PropTypes.shape(),
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JobShareModal;
