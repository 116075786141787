import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Pagination,
  Spinner,
  Grid,
  Heading,
  Icon,
  LinkButton,
} from '@lionstep/ui';
import { Helmet } from 'react-helmet';

// Components
import JobCard from './components/JobCard';
import ArchiveJobModal from './components/ArchiveJobModal/ArchiveJobModal';
import DeleteJobModal from './components/DeleteJobModal/DeleteJobModal';
import JobMailingPermissionsModal from './components/JobMailingPermissionsModal';
import JobShareModal from './components/JobShareModal';
import ValidatedCandidates from './components/ValidatedCandidates';
import PublishJobModal from './components/PublishJobModal/PublishJobModal';
import { JobPlaceholder } from '../../../../components/refactoring/placeholders';
import Filters from './components/Filters';

// actions
import { deleteJob, archiveJob, publishJob } from '../../redux/actions';

// hooks
import useSearchParams from '../../../../hooks/useSearchParams';
import useIsLionstepAdmin from '../../../../hooks/useIsLionstepAdmin';

// constants
import { PAGE_SIZE, JOB_DROPDOWN_ACTIONS } from '../../dashboard.constants';

// Queries
import {
  useUserQuery,
  useUserCollaborators,
} from '../../../../queries/userQueries';
import { useDashboardJobs } from '../../../../queries/jobQueries';

// Styles
import styles from './dashboard.module.scss';

const Dashboard = () => {
  const isLionstepAdmin = useIsLionstepAdmin();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);

  const { data: user } = useUserQuery();
  useUserCollaborators();

  const { params, setQueryParams } = useSearchParams();
  const { page: initialPage = 1, archived = false } = params;
  const {
    data: { result: jobs, count: totalJobs },
    refetch: getDashboardJobs,
    isFetching: isLoading,
  } = useDashboardJobs({
    params: {
      limit: PAGE_SIZE,
      archived: !!params.archived,
      offset:
        params.page && params.page > 0 ? PAGE_SIZE * (params.page - 1) : 0,
      ...(params.collaborator && { user: params.collaborator }),
    },
  });

  const onPaginationChange = async (page) => {
    try {
      setQueryParams({ ...params, page });

      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch {
      // DO NOTHING
    }
  };

  const refetchJobsOnArchiveDeletePublish = () => {
    const lastPage = Math.ceil((totalJobs - 1) / PAGE_SIZE);
    const currentPage = initialPage <= lastPage ? initialPage : lastPage;

    setQueryParams({ ...params, page: currentPage });
    getDashboardJobs();
  };

  const onJobArchive = async () => {
    try {
      await dispatch(
        archiveJob({
          id: selectedJob.id,
          title: selectedJob.opening_title,
          user,
        }),
      );
      setSelectedJob(null);
      setSelectedModal(null);
      refetchJobsOnArchiveDeletePublish();
    } catch {
      // DO NOTHING
    }
  };

  const onJobDelete = async () => {
    try {
      await dispatch(deleteJob({ id: selectedJob.id }));
      setSelectedJob(null);
      setSelectedModal(null);
      refetchJobsOnArchiveDeletePublish();
    } catch {
      // DO NOTHING
    }
  };

  const onJobPublish = async () => {
    try {
      await dispatch(
        publishJob({
          id: selectedJob.id,
          title: selectedJob.opening_title,
          user,
        }),
      );
      setSelectedJob(null);
      setSelectedModal(null);
      refetchJobsOnArchiveDeletePublish();
    } catch {
      // DO NOTHING
    }
  };

  const onActionModalOpen = ({ job, modalType }) => {
    setSelectedJob(job);
    setSelectedModal(modalType);
  };
  const onActionModalClose = () => setSelectedModal(null);

  return (
    <Grid.Container className={styles.dashboard}>
      <Helmet>
        <title>{t('dashboard')} | Lionstep</title>
      </Helmet>

      {isLoading && <Spinner position="fixed" size={100} />}
      {/* Title */}
      <Grid.Row gutter={30}>
        <Grid.Col
          className={styles.header}
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          <Heading className={styles.title}>
            {archived ? (
              <>
                {t('dashboard_page.title.archived')}
                <span className={styles.totalCount}>{totalJobs} Archived</span>
              </>
            ) : (
              <>
                {t('dashboard_page.title.active')}
                <span className={styles.totalCount}>{totalJobs} Active</span>
              </>
            )}
          </Heading>
          {isLionstepAdmin && (
            <LinkButton
              to="/create-job"
              component={Link}
              data-testid="add-new-job-btn"
              className={styles.addNewJobBtn}
              type="secondary"
            >
              <Icon name="Plus" /> {t('dashboard_page.add_new_job_btn')}
            </LinkButton>
          )}
        </Grid.Col>
      </Grid.Row>
      {!archived && (
        <Grid.Row gutter={30}>
          <Grid.Col
            xs={24}
            xl={{ span: 22, offset: 1 }}
            xxl={{ span: 18, offset: 3 }}
          >
            <ValidatedCandidates isLoadingDashboardJobs={isLoading} />
          </Grid.Col>
        </Grid.Row>
      )}

      {((!archived && isLionstepAdmin && !!jobs.length) ||
        (!archived &&
          isLionstepAdmin &&
          !jobs.length &&
          params.collaborator)) && (
        <Grid.Row>
          <Grid.Col
            xs={24}
            xl={{ span: 22, offset: 1 }}
            xxl={{ span: 18, offset: 3 }}
          >
            <Filters />
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row gutter={[30, 30]}>
        {/* Render Jobs */}
        {jobs.length &&
          jobs.map((job, index) => (
            <Grid.Col
              xs={24}
              lg={12}
              xl={{ span: 11, offset: index % 2 === 0 ? 1 : 0 }}
              xxl={{ span: 9, offset: index % 2 === 0 ? 3 : 0 }}
              key={job.id}
            >
              <JobCard
                job={job}
                archived={archived}
                onActionModalOpen={onActionModalOpen}
              />
            </Grid.Col>
          ))}
        {/* Render Empty message */}
        {!isLoading && !jobs.length && (
          <Grid.Col xs={24}>
            <JobPlaceholder position="right">
              <span className={styles.placeholderText}>
                {archived
                  ? t('dashboard_page.no_archived_jobs')
                  : t('dashboard_page.no_active_jobs')}
              </span>
            </JobPlaceholder>
          </Grid.Col>
        )}
      </Grid.Row>
      {/* Render Pagination */}
      <Grid.Row className={styles.paginationWrapper} gutter={30}>
        <Grid.Col
          xs={24}
          xl={{ span: 22, offset: 1 }}
          xxl={{ span: 18, offset: 3 }}
        >
          {!!jobs.length && (
            <Pagination
              className={styles.pagination}
              currentPage={Number(initialPage)}
              pageSize={PAGE_SIZE}
              totalPages={totalJobs}
              onChange={onPaginationChange}
            />
          )}
        </Grid.Col>
      </Grid.Row>

      {/* Action Modals */}
      <DeleteJobModal
        show={selectedModal === JOB_DROPDOWN_ACTIONS.deleteJob}
        onConfirm={onJobDelete}
        onClose={onActionModalClose}
      />
      <ArchiveJobModal
        show={selectedModal === JOB_DROPDOWN_ACTIONS.archiveJob}
        onConfirm={onJobArchive}
        onClose={onActionModalClose}
      />
      <PublishJobModal
        show={selectedModal === JOB_DROPDOWN_ACTIONS.publishJob}
        onConfirm={onJobPublish}
        onClose={onActionModalClose}
      />
      <JobMailingPermissionsModal
        show={selectedModal === JOB_DROPDOWN_ACTIONS.jobMailingPermissions}
        job={jobs.find((job) => job.id === selectedJob?.id) || null}
        onClose={onActionModalClose}
      />

      <JobShareModal
        show={selectedModal === JOB_DROPDOWN_ACTIONS.shareJob}
        job={jobs.find((job) => job.id === selectedJob?.id) || null}
        onClose={onActionModalClose}
      />
    </Grid.Container>
  );
};

export default Dashboard;
