import { axios } from 'axios';
import { toast } from '@lionstep/ui';
import download from 'downloadjs';

// API
import {
  getSingleJobCandidateApi,
  patchJobCandidateApi,
} from '../../../../api/job.api';

import { layer1Axios } from '../../../../api/http';

// Types
import * as types from './candidate.types';

import { LIONSTEP_INTERNAL_TOKEN } from '../../../../config';

export const getCandidate =
  ({ jobId, candidateId }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_CANDIDATE_START });

      const { data } = await getSingleJobCandidateApi({
        id: jobId,
        candidateId,
      });

      dispatch({ type: types.GET_CANDIDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({ type: types.GET_CANDIDATE_FAIL });

      toast.error('There was error while fetching candidate.', {
        id: types.GET_CANDIDATE_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const getCandidatePDFReport =
  ({ jobId, candidateId, data }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.GET_CANDIDATE_PDF_REPORT_START });

      const { data: downloadLink } = await layer1Axios({
        method: 'POST',
        url: `/v3/main/jobs/${jobId}/contact_talents/${candidateId}/pdf`,
        data,
      });

      download(downloadLink);

      dispatch({ type: types.GET_CANDIDATE_PDF_REPORT_SUCCESS });
    } catch (error) {
      dispatch({ type: types.GET_CANDIDATE_PDF_REPORT_FAIL });

      toast.error('There was error while fetching candidate.', {
        id: types.GET_CANDIDATE_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const sendSmsReminder = (data) => async (dispatch) => {
  try {
    dispatch({ type: types.SEND_SMS_REMINDER_START });

    const url = `//${
      process.env.REACT_APP_NODE_ENV ? 'qa.' : ''
    }candidate.lionstep.com/api/v2/candidates/send_reminder_sms`;

    await axios({
      method: 'POST',
      url,
      data,
      headers: {
        'X-Lionstep-Internal-Token': LIONSTEP_INTERNAL_TOKEN,
      },
    });

    dispatch({ type: types.SEND_SMS_REMINDER_SUCCESS });
    toast.success('SMS Reminder successfully sent', {
      id: types.SEND_SMS_REMINDER_SUCCESS,
    });
  } catch (error) {
    dispatch({ type: types.SEND_SMS_REMINDER_FAIL });
    toast.error('Error sending SMS Reminder.', {
      id: types.SEND_SMS_REMINDER_FAIL,
      statusCode: error?.response?.status,
    });
  }
};

export const hireCandidate =
  ({ jobId, candidateId, status, job_start_date }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.HIRE_CANDIDATE_START });

      const { data } = await patchJobCandidateApi({
        id: jobId,
        candidateId,
        data: {
          status,
          job_start_date,
        },
      });

      dispatch({ type: types.HIRE_CANDIDATE_SUCCESS, payload: data });
      toast.success('Candidate hired successfully.', {
        id: types.HIRE_CANDIDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.HIRE_CANDIDATE_FAIL });

      toast.error('There was an error hiring candidate.', {
        id: types.HIRE_CANDIDATE_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const rejectCandidate =
  ({
    jobId,
    candidateId,
    status,
    comment,
    rejectedByEmail,
    rejectedByName,
    rejectionFeedback,
  }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.REJECT_CANDIDATE_START });

      const { data } = await patchJobCandidateApi({
        id: jobId,
        candidateId,
        data: {
          status,
          rejection_feedback: rejectionFeedback,
          comment,
          rejected_by_email: rejectedByEmail,
          rejected_by_name: rejectedByName,
        },
      });

      dispatch({ type: types.REJECT_CANDIDATE_SUCCESS, payload: data });
      toast.success('Candidate rejected successfully.', {
        id: types.REJECT_CANDIDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.REJECT_CANDIDATE_FAIL });

      toast.error('There was an error rejecting candidate.', {
        id: types.REJECT_CANDIDATE_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const updateCandidateFeedback =
  ({ jobId, candidateId, feedback }) =>
  async (dispatch) => {
    try {
      dispatch({ type: types.FEEDBACK_CANDIDATE_START });

      const { data } = await patchJobCandidateApi({
        id: jobId,
        candidateId,
        data: {
          feedback,
        },
      });

      dispatch({ type: types.FEEDBACK_CANDIDATE_SUCCESS, payload: data });
      toast.success('Candidate feedback updated successfully.', {
        id: types.FEEDBACK_CANDIDATE_SUCCESS,
      });
    } catch (error) {
      dispatch({ type: types.FEEDBACK_CANDIDATE_FAIL });

      toast.error('There was an error updating candidate feedback.', {
        id: types.FEEDBACK_CANDIDATE_FAIL,
        statusCode: error?.response?.status,
      });
    }
  };

export const candidateCreditRefunded = (isRefunded) => (dispatch) => {
  if (isRefunded) {
    dispatch({ type: types.CANDIDATE_CREDIT_REFUNDED_SUCCESS });
  }
};

export const resetCandidate = () => ({
  type: types.RESET_CANDIDATE,
});
