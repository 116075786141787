import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon } from '@lionstep/ui';
import classnames from 'classnames';

// Actions
import { getCreditsHistory } from '../../../../../company/redux/actions';
import { hireCandidate, rejectCandidate } from '../../candidate.actions';

// Selectors
import { candidateSelector } from '../../candidate.selectors';
import { creditsHistorySelector } from '../../../../../company/redux/selectors/credits.selectors';

// Constants
import { CANDIDATES_STATUSES } from '../../../../job.constants';

// Components
import CandidateBasicInfo from './CandidateBasicInfo';
import CandidateActionsAndExtraInfo from './CandidateActionsAndExtraInfo';
import HireCandidateModal from '../hireCandidateModal';
import RejectCandidateModal from '../rejectCandidateModal';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';
import { useJobCompactQuery } from '../../../../../../queries/jobQueries';

// Styles
import styles from './candidateHeader.module.scss';

const cx = classnames.bind(styles);

const CandidateHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: jobId } = useParams();
  const history = useHistory();
  const candidate = useSelector(candidateSelector);
  const creditsHistory = useSelector(creditsHistorySelector);

  // Queries
  const { data: user } = useUserQuery();
  const { data: job } = useJobCompactQuery({
    id: jobId,
    options: { enabled: false },
  });

  const [showHireModal, setShowHireModal] = useState(false);
  const handleOnHireModalOpen = () => {
    setShowHireModal(true);
  };
  const handleOnHireModalClose = () => {
    setShowHireModal(false);
  };
  const [showRejectModal, setShowRejectModal] = useState(false);
  const handleOnRejectModalOpen = () => {
    setShowRejectModal(true);
  };
  const handleOnRejectModalClose = () => {
    setShowRejectModal(false);
  };

  useEffect(() => {
    if (!creditsHistory.length) {
      dispatch(getCreditsHistory({ companyId: user.company_id }));
    }
  }, [dispatch]);

  const handleOnHireCandidate = async (date) => {
    await dispatch(
      hireCandidate({
        jobId,
        candidateId: candidate.id,
        job_start_date: date.toISOString().slice(0, -1),
        status: CANDIDATES_STATUSES.hired.value,
      }),
    );

    handleOnHireModalClose();
  };

  const handleOnRejectCandidate = async ({
    rejectionReason,
    rejectionFeedback,
  }) => {
    await dispatch(
      rejectCandidate({
        jobId,
        candidateId: candidate.id,
        status: CANDIDATES_STATUSES.rejected.value,
        comment: rejectionReason,
        rejectionFeedback,
        rejectedByEmail: user.email,
        rejectedByName: user.displayName,
      }),
    );

    handleOnRejectModalClose();
  };

  const handleOnClickBack = () => {
    if (history.length > 1) history.goBack();
  };

  return (
    <>
      <div className={styles.topLinksWrapper}>
        <span
          className={cx(styles.linkBack, {
            [styles.disabled]: history.length === 1,
          })}
          onClick={handleOnClickBack}
        >
          <Icon name="ArrowLeft" />{' '}
          {t('candidate_page.header.back_to_candidates')}
        </span>

        <span className={styles.jobTitle}>{job.opening_title}</span>
      </div>

      <div className={styles.candidateCard}>
        <CandidateBasicInfo candidate={candidate} />

        <CandidateActionsAndExtraInfo
          job={job}
          candidate={candidate}
          onHireModalOpen={handleOnHireModalOpen}
          onRejectModalOpen={handleOnRejectModalOpen}
        />
      </div>

      <HireCandidateModal
        show={showHireModal}
        onClose={handleOnHireModalClose}
        onConfirm={handleOnHireCandidate}
      />

      <RejectCandidateModal
        show={showRejectModal}
        onClose={handleOnRejectModalClose}
        onConfirm={handleOnRejectCandidate}
      />
    </>
  );
};

export default CandidateHeader;
