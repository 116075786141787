import * as types from '../types/credits.types';

const initialState = {
  total: 0,
  history: [],
  packages: [],
  purchases: [],
  isLoadingTotal: false,
  isLoadingHistory: false,
  isLoadingPackages: false,
  isLoadingRefund: false,
  isLoadingAddCredits: false,
  isLoadingChargeCredits: false,
  isLoadingPurchases: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    /**
     * Total credits
     */
    case types.GET_CREDITS_TOTAL_START:
      return {
        ...state,
        isLoadingTotal: true,
      };

    case types.GET_CREDITS_TOTAL_SUCCESS:
      return {
        ...state,
        total: action.payload,
        isLoadingTotal: false,
      };

    case types.GET_CREDITS_TOTAL_FAIL:
      return {
        ...state,
        isLoadingTotal: false,
      };

    /**
     * Credits history
     */
    case types.GET_CREDITS_HISTORY_START:
      return {
        ...state,
        isLoadingHistory: true,
      };

    case types.GET_CREDITS_HISTORY_SUCCESS:
      return {
        ...state,
        history: action.payload,
        isLoadingHistory: false,
      };

    case types.GET_CREDITS_HISTORY_FAIL:
      return {
        ...state,
        isLoadingHistory: false,
      };

    /**
     * Credits packages
     */
    case types.GET_CREDITS_PACKAGES_START:
      return {
        ...state,
        isLoadingPackages: true,
      };

    case types.GET_CREDITS_PACKAGES_SUCCESS:
      return {
        ...state,
        packages: action.payload,
        isLoadingPackages: false,
      };

    case types.GET_CREDITS_PACKAGES_FAIL:
      return {
        ...state,
        isLoadingPackages: false,
      };

    /**
     * Credit refund
     */
    case types.REFUND_CREDIT_START:
      return {
        ...state,
        isLoadingRefund: true,
      };

    case types.REFUND_CREDIT_SUCCESS:
    case types.REFUND_CREDIT_FAIL:
      return {
        ...state,
        isLoadingRefund: false,
      };

    /**
     * Add credits
     */
    case types.ADD_CREDITS_START:
      return {
        ...state,
        isLoadingAddCredits: true,
      };

    case types.ADD_CREDITS_SUCCESS:
      return {
        ...state,
        isLoadingAddCredits: false,
        purchases: [action.payload, ...state.purchases],
      };
    case types.ADD_CREDITS_FAIL:
      return {
        ...state,
        isLoadingAddCredits: false,
      };

    /**
     * Charge credits
     */
    case types.CHARGE_CREDITS_START:
      return {
        ...state,
        isLoadingChargeCredits: true,
      };

    case types.CHARGE_CREDITS_SUCCESS:
    case types.CHARGE_CREDITS_FAIL:
      return {
        ...state,
        isLoadingChargeCredits: false,
      };

    /**
     * Purchases
     */
    case types.GET_CREDITS_PURCHASES_START:
      return {
        ...state,
        isLoadingPurchases: true,
        purchases: [],
      };

    case types.GET_CREDITS_PURCHASES_SUCCESS:
      return {
        ...state,
        isLoadingPurchases: false,
        purchases: action.payload,
      };

    case types.GET_CREDITS_PURCHASES_FAIL:
    case types.UPDATE_PURCHASE_EXPIRATION_FAIL:
      return {
        ...state,
        isLoadingPurchases: false,
      };

    case types.UPDATE_PURCHASE_EXPIRATION_START:
      return {
        ...state,
        isLoadingPurchases: true,
      };

    case types.UPDATE_PURCHASE_EXPIRATION_SUCCESS:
      return {
        ...state,
        isLoadingPurchases: false,
        purchases: state.purchases.map((purchase) => {
          if (purchase.id === action.payload.id) {
            return action.payload;
          }

          return purchase;
        }),
      };

    default:
      return state;
  }
};
