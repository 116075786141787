import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Tooltip } from '@lionstep/ui';
import { capitalize } from 'lodash';
import classnames from 'classnames';
import moment from 'moment';

// Components
import Flag from '../../../../../../components/refactoring/flag';
import NoElasticSearchBadge from '../../../../../../components/refactoring/noElasticSearchBadge';
import PreferredCompaniesTooltip from '../../../../components/PreferredCompaniesTooltip';

// Hooks
import useIsLionstepAdmin from '../../../../../../hooks/useIsLionstepAdmin';

// Constants
import { DATE_FORMAT } from '../../../../../../constants/date.const';
import { CANDIDATES_STATUSES } from '../../../../job.constants';
import { USER_ROLES } from '../../../../../../constants/user.const';

// Queries
import { useUserQuery } from '../../../../../../queries/userQueries';

// Styles
import styles from './candidateCard.module.scss';

const cx = classnames.bind(styles);

const CandidateCard = ({ job, candidate, onClick }) => {
  const { t } = useTranslation();
  const isLionstepAdmin = useIsLionstepAdmin();
  const { data: user } = useUserQuery();

  const resolvedStatuses = [
    CANDIDATES_STATUSES.hired.value,
    CANDIDATES_STATUSES.interested.value,
    CANDIDATES_STATUSES.rejected.value,
  ];

  const shouldDisplayPersonalInfo =
    isLionstepAdmin || resolvedStatuses.includes(candidate.status);

  const handleOnCardClick = () => {
    if (
      shouldDisplayPersonalInfo &&
      ((candidate.status === CANDIDATES_STATUSES.interested.value &&
        candidate.paid) ||
        candidate.status !== CANDIDATES_STATUSES.interested.value)
    ) {
      onClick();
    }
  };

  const formatCandidateSource = (source) => {
    if (!source || source === 'lionstep_link') return 'Active sourcing';
    return capitalize(source.split('_').join(' '));
  };

  const validityValid =
    !candidate.validity || candidate.validity === 'valid_candidate';
  const validityAcceptable = candidate.validity === 'acceptable_candidate';
  const isATSJob = !!job.kombo_job;

  return (
    <div
      className={cx(styles.candidateCard, {
        [styles.notPaidCard]:
          !candidate.paid &&
          candidate.status === CANDIDATES_STATUSES.interested.value,
        [styles.notClickable]: !shouldDisplayPersonalInfo,
      })}
      onClick={handleOnCardClick}
    >
      <div className={cx(styles.cardHeader)}>
        <div className={styles.candidate}>
          <div className={styles.candidateImageContainer}>
            <img
              className={styles.candidateImage}
              src={
                candidate.image_url ||
                `/img/dummyImages/${candidate.dummy_image}`
              }
              onError={(img) => {
                // eslint-disable-next-line no-param-reassign
                img.target.src = `/img/dummyImages/${candidate.dummy_image}`;
              }}
              alt={candidate.full_name || ''}
            />
            {!candidate.has_es_profile && isLionstepAdmin && (
              <NoElasticSearchBadge
                className={styles.noElasticSearchIcon}
                id={`no-elastic-search-badge-${candidate.id}`}
              />
            )}
          </div>
          <div className={styles.fullNameAndPosition}>
            {candidate.full_name && shouldDisplayPersonalInfo && (
              <div className={styles.fullName}>
                <div className={styles.fullNameText}>{candidate.full_name}</div>
                {candidate.preferred_company && (
                  <PreferredCompaniesTooltip candidate={candidate} />
                )}
                {candidate.status === CANDIDATES_STATUSES.interested.value &&
                  (validityValid || validityAcceptable) && (
                    <Tooltip
                      id={`candidate-card-tooltip-${candidate.id}`}
                      place="top"
                      content={
                        validityValid
                          ? t('candidates_page.candidate_card.valid_candidate')
                          : t(
                              'candidates_page.candidate_card.acceptable_candidate',
                            )
                      }
                    >
                      <Icon
                        className={cx(styles.fullNameValidity, {
                          [styles.fullNameValidityValid]: validityValid,
                          [styles.fullNameValidityAcceptable]:
                            validityAcceptable,
                        })}
                        name="Validation"
                      />
                    </Tooltip>
                  )}
              </div>
            )}
            {candidate.position && shouldDisplayPersonalInfo && (
              <div className={styles.position}>{candidate.position}</div>
            )}
            {((!candidate.full_name && !candidate.position) ||
              !shouldDisplayPersonalInfo) && (
              <div className={styles.position}>
                {t('candidate_page.header.anonymous_profile')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={cx(styles.cardBody)}>
        <div className={styles.locationAndLanguages}>
          {candidate.location && (
            <span className={styles.location}>
              <Icon name="Pin" className={styles.locationIcon} />
              <span className={styles.locationName}>{candidate.location}</span>
            </span>
          )}
          {!!candidate.languages.length && (
            <span className={styles.languages}>
              {candidate.languages.length > 3 && (
                <span className={styles.additionalLanguages}>
                  +{candidate.languages.length - 3}
                </span>
              )}
              {candidate.languages.slice(0, 3).map((language) => (
                <Flag
                  key={language}
                  country={language}
                  className={styles.languageFlag}
                />
              ))}
            </span>
          )}
        </div>
        <div className={styles.info}>
          <div className={styles.labels}>
            {user.role !== USER_ROLES.viewer && (
              <div className={styles.labelItem}>
                {t('candidates_page.candidate_card.salary.label')}:
              </div>
            )}
            <div className={styles.labelItem}>
              {t('candidates_page.candidate_card.source.label')}:
            </div>
            {isATSJob && <div className={styles.labelItem}>ATS Synced:</div>}
          </div>
          <div className={styles.values}>
            {user.role !== USER_ROLES.viewer && (
              <div className={styles.valueItem}>
                {candidate.salary ? `${candidate.salary} ${job.currency}` : '-'}
              </div>
            )}
            <div className={styles.valueItem}>
              {formatCandidateSource(candidate.utm_source)}
            </div>
            {isATSJob && (
              <div className={styles.valueItem}>
                {candidate.kombo_application
                  ? moment(candidate.kombo_application.synced_at).format(
                      DATE_FORMAT,
                    )
                  : '-'}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Credit not paid overlay */}
      {candidate.status === 'interested' && !candidate.paid && (
        <div className={styles.notPaidContent}>
          <div className={styles.notPaidTitle}>{t('interested_candidate')}</div>

          <div className={styles.notPaidDesc}>{t('no_credits_message')}</div>
        </div>
      )}
    </div>
  );
};

CandidateCard.propTypes = {
  job: PropTypes.shape({
    kombo_job: PropTypes.bool,
    currency: PropTypes.string,
  }).isRequired,
  candidate: PropTypes.shape({
    experience: PropTypes.number,
    dummy_image: PropTypes.string,
    image_url: PropTypes.string,
    full_name: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.string),
    location: PropTypes.string,
    position: PropTypes.string,
    salary: PropTypes.number,
    utm_source: PropTypes.string,
    paid: PropTypes.bool,
    status: PropTypes.string,
    preferred_company: PropTypes.string,
    has_es_profile: PropTypes.bool,
    id: PropTypes.string,
    validity: PropTypes.oneOf([
      'valid_candidate',
      'acceptable_candidate',
      'invalid_candidate',
    ]),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CandidateCard;
