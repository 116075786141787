import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from '@lionstep/ui';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

// Components
import CandidateHeader from './components/candidateHeader';
import CandidateBody from './components/candidateBody';

// Actions
import { getCandidate, resetCandidate } from './candidate.actions';

// Selectors
import {
  candidateSelector,
  isLoadingCandidateSelector,
  isLoadingUpdateSelector,
} from './candidate.selectors';

// Queries
import { useJobCompactQuery } from '../../../../queries/jobQueries';

// Styles
import styles from './candidate.module.scss';

const Candidate = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isLoadingCandidate = useSelector(isLoadingCandidateSelector);
  const isUpdatingCandidate = useSelector(isLoadingUpdateSelector);
  const { id: jobId, candidateId } = useParams();
  const candidatePageRef = useRef();

  const candidate = useSelector(candidateSelector);

  const { data: job, isFetching: isLoadingJob } = useJobCompactQuery({
    id: jobId,
  });

  useEffect(() => {
    dispatch(getCandidate({ jobId, candidateId }));

    return () => dispatch(resetCandidate());
  }, []);

  if (isLoadingJob || isLoadingCandidate)
    return <Spinner position="fixed" size={100} />;

  return (
    job &&
    candidate && (
      <div className={styles.candidatePage} ref={candidatePageRef}>
        <Helmet>
          <title>{t('candidates_page.title')} | Lionstep</title>
        </Helmet>

        {isUpdatingCandidate && <Spinner position="fixed" size={100} />}

        <CandidateHeader />
        <CandidateBody />
      </div>
    )
  );
};

export default Candidate;
