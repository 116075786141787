import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Modal, Spinner, Text, Table, toast } from '@lionstep/ui';

// Components
import InfoPlusIconWithTooltip from '../../../../../../components/refactoring/infoPlusIconWithTooltip';

// Queries
import { useUserCollaborators } from '../../../../../../queries/userQueries';
import {
  useAddJobMailingPermissionsMutation,
  useDeleteJobMailingPermissionsMutation,
} from '../../../../../../queries/jobQueries';

// Styles
import styles from './jobMailingPermissionsModal.module.scss';

const JobMailingPermissionsModal = ({ show, job, onClose }) => {
  const { t } = useTranslation();
  const {
    data: collaborators,
    isFetching,
    refetch: getUserCollaborators,
  } = useUserCollaborators({
    options: { enabled: false },
  });

  // Add Job Mailing Permission
  const { mutate: addJobMailingPermissions } =
    useAddJobMailingPermissionsMutation({
      onSuccess: () => {
        getUserCollaborators();
      },
      onError: (error) => {
        toast.error('Error adding mailing permission to a collaborator', {
          id: 'ADD_COLLABORATOR_MAILING_PERMISSION_FAIL',
          statusCode: error?.response?.status,
        });
      },
    });

  // Delete Job Mailing Permission
  const { mutate: deleteJobMailingPermissions } =
    useDeleteJobMailingPermissionsMutation({
      onSuccess: () => {
        getUserCollaborators();
      },
      onError: (error) => {
        toast.error('Error adding mailing permission to a collaborator', {
          id: 'REMOVE_COLLABORATOR_MAILING_PERMISSION_FAIL',
          statusCode: error?.response?.status,
        });
      },
    });

  const handleToggleMailingPermission = (e, userId) => {
    const jobId = job.id;
    const data = { user_id: userId };

    if (e.target.checked) {
      addJobMailingPermissions({ jobId, data });
    } else {
      deleteJobMailingPermissions({ jobId, data });
    }
  };

  return (
    <Modal className={styles.modal} show={show} onClose={onClose}>
      <Modal.Header className={styles.modalHeader}>
        {t('dashboard_page.modals.mailing_permissions_title')}
        <InfoPlusIconWithTooltip />
        <Text className={styles.modalSubHeader}>
          {job?.opening_title || ''}
        </Text>
      </Modal.Header>

      <Modal.Body className={styles.modalBody}>
        {isFetching && <Spinner position="absolute" size={100} />}

        <Table>
          <Table.Header>
            <Table.HeaderRow>
              <Table.HeaderCell colSpan={2}>{t('email')}</Table.HeaderCell>
              <Table.HeaderCell />
              <Table.HeaderCell textAlign="center">
                {t('dashboard_page.modals.mailing_permissions_title')}
              </Table.HeaderCell>
            </Table.HeaderRow>
          </Table.Header>

          <Table.Body>
            {collaborators.map((collaborator) => {
              const onMailingList = collaborator.job_mailings.includes(
                job?.id.toString(),
              );
              return (
                <Table.Row key={collaborator.local_id}>
                  <Table.Cell colSpan={2}>{collaborator.email}</Table.Cell>
                  <Table.Cell />
                  <Table.Cell textAlign="center">
                    <Checkbox
                      name={`mailing-${collaborator.local_id}`}
                      label={''}
                      checked={onMailingList}
                      onChange={(e) =>
                        handleToggleMailingPermission(e, collaborator.local_id)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Modal.Body>
      <Modal.Footer
        confirmText={t('submit')}
        onConfirm={onClose}
        confirmButtonGhost
      />
    </Modal>
  );
};

JobMailingPermissionsModal.defaultProps = {
  job: {},
};

JobMailingPermissionsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  job: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};

export default JobMailingPermissionsModal;
